
/**
 * Creates object entries for onboarding data.
 */
export const createOnboardingServiceData = (entityId, onboardingService, stationType, stationTypeId) => {
    return {
        entityId,
        onboardingService,
        stationType,
        stationTypeId
    };
}

/**
 * Creates object entries for organization data.
 */
export const createOrganizationData = (entityId, name) => {
    return {
        entityId,
        name
    };
}

/**
 * Creates object entries for project data.
 */
export const createProjectData = (entityId, name, startDate, endDate) => {
    return {
        entityId,
        name,
        startDate,
        endDate
    };
}

/**
 * Creates object entries for station type data.
 */
export const createStationTypeData = (entityId, name, description) => {
    return {
        entityId,
        name,
        description
    };
}

/**
 * Creates object entries for station data.
 */
export const createStationData = ( entityId, organizationId, stationTypeId, name, organization, accessLevel, stationType, onboarding) => {
    return {
        entityId,
        organizationId,
        stationTypeId,
        name,
        organization,
        accessLevel,
        stationType,
        onboarding
    };
}