import * as React from 'react';
import ApiTable from "../Components/Table/ApiTable";
import { useState, useEffect } from 'react';
import ApiService from "../Services/ApiService";
import { ORGANIZATIONHEAD } from "../Utils/constants/tableHeaders";
import OrganizationFilter from "../Components/FilterSections/OrganizationFilter.js";
import OrganizationAlter from '../Components/AlterSections/OrganizationAlter';
import OrganizationIcon from '../Components/PageIcons/OrganizationIcon';
import { navbarIconSx } from '../Utils/styles/sxStyles';
import { transformOrganizationData } from '../Utils/helpers/apiTransformation';
import { ENDPOINT_ORGANIZATIONS } from '../Utils/constants/apiEndpointCodes';
import { TITLE_PLURAL_ORGANIZATION } from '../Utils/constants/titleCodes';
import { useStore } from '../Services/StoreService';
import PageWrapper from '../Components/PageWrapper';

const OrganizationsPage = () => {

    const [rows, setRows] = useState([]);
    const [dataView, setDataView] = useState([]);
    const [hasData, setHasData] = useState(false);
    const [globalState, dispatch] = useStore();

    useEffect(() => {
        if (globalState.isSuccess || !hasData) {
            ApiService.apiGetRequest(ENDPOINT_ORGANIZATIONS, (res) => transformOrganizationData(res, setRows));
            setDataView(rows);
            setHasData(true);
            dispatch('SUCCESSFUL_REQUEST', false);
        }
    }, [hasData, rows, globalState]);

    return (
        <PageWrapper
            title={TITLE_PLURAL_ORGANIZATION}
            icon={<OrganizationIcon sx={navbarIconSx} />}
        >
            <OrganizationAlter
                rows={rows}
            />
            <OrganizationFilter
                rows={rows}
                setDataView={setDataView}
                setHasData={setHasData}
            />
            <ApiTable
                headCells={ORGANIZATIONHEAD}
                rows={dataView}
                setRows={setRows}
            />
        </PageWrapper>
    )
}

export default OrganizationsPage;