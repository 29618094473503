import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import EntityDisplay from './EntityDisplay';
import StyledOutlinedButton from '../../../StyledButtons/StyledOutlinedButton';
import ApiStation from '../../../../Utils/dataAccessClasses/ApiStation';
import ApiService from '../../../../Services/ApiService';
import ApiOrganization from '../../../../Utils/dataAccessClasses/ApiOrganization';
import ApiStationType from '../../../../Utils/dataAccessClasses/ApiStationType';
import ApiProject from '../../../../Utils/dataAccessClasses/ApiProject';
import ApiOnboardingService from '../../../../Utils/dataAccessClasses/ApiOnboardingService';
import ApiStationOnboarding from '../../../../Utils/dataAccessClasses/ApiStationOnboarding';
import { FORM_FIELD_ENTITYID, FORM_FIELD_STATIONTYPEID, FORM_FIELD_ORGANIZATIONID } from '../../../../Utils/constants/formFieldCodes';
import {
    TITLE_SINGULAR_ONBOARDING,
    TITLE_SINGULAR_ONBOARDING_SERVICE,
    TITLE_SINGULAR_ORGANIZATION,
    TITLE_SINGULAR_PROJECT,
    TITLE_SINGULAR_STATION,
    TITLE_SINGULAR_STATION_TYPE
} from '../../../../Utils/constants/titleCodes';
import { useStore } from '../../../../Services/StoreService';


const AddCompleteDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useStore()[1];

    const goBackAndRefresh = (response) => {
        let isSuccessful = !!response ? false : true;
        props.setOpenCompleteDialog(!isSuccessful);
        props.setOpenAlterDialog(!isSuccessful);
        dispatch('SUCCESSFUL_REQUEST', isSuccessful);
    };

    const handleSubmit = () => {
        const entity = props.entityForm.entity;
        switch (props.title) {
            case TITLE_SINGULAR_STATION:
                const station = new ApiStation();
                station.addAccessLevel(entity.accessLevel[0]);
                station.addName(entity.name);
                station.addOrganization(entity.organizationId);
                station.addStationType(entity.stationTypeId);

                ApiService.apiPostRequest(station.getPostLink(), station.getData())
                    .then(res => {
                        goBackAndRefresh(res);
                    });
                break;
            case TITLE_SINGULAR_ORGANIZATION:
                const organization = new ApiOrganization();
                organization.addName(entity.name);

                ApiService.apiPostRequest(organization.getPostLink(), organization.getData())
                    .then(res => {
                        goBackAndRefresh(res);
                    });
                break;
            case TITLE_SINGULAR_STATION_TYPE:
                const stationType = new ApiStationType();
                stationType.addName(entity.name);
                stationType.addDescription(entity.description);

                ApiService.apiPostRequest(stationType.getPostLink(), stationType.getData())
                    .then(res => {
                        goBackAndRefresh(res);
                    });
                break;
            case TITLE_SINGULAR_PROJECT:
                const project = new ApiProject();
                project.addName(entity.name);
                project.addStartDate(entity.startDate);
                project.addEndDate(entity.endDate);

                ApiService.apiPostRequest(project.getPostLink(), project.getData())
                    .then(res => {
                        goBackAndRefresh(res);
                    });
                break;
            case TITLE_SINGULAR_ONBOARDING_SERVICE:
                const onboardingService = new ApiOnboardingService();
                onboardingService.addUri(entity.onboardingService);
                onboardingService.addStationType(entity.stationTypeId);

                ApiService.apiPostRequest(onboardingService.getPostLink(), onboardingService.getData())
                    .then(res => {
                        goBackAndRefresh(res);
                    });
                break;

            case TITLE_SINGULAR_ONBOARDING:
                const onboarding = new ApiStationOnboarding();
                onboarding.addEntityId(entity.onboardingServiceId);
                onboarding.addStation(entity.stationId);
                onboarding.addEmail(entity.email);

                ApiService.apiPostRequest(onboarding.getPostLink(), onboarding.getData())
                    .then(res => {
                        goBackAndRefresh(res);
                    });
                break;
            default:
                break;
        }
    };

    const handleGoBack = () => {
        props.setOpenCompleteDialog(false);
    };

    const formatEntity = (el) => {
        try {
            let formattedEntry = {};
            Object.entries(el).map(([key, value]) => {
                try {
                    // Do not display these fields
                    const isIdField = [FORM_FIELD_ORGANIZATIONID, FORM_FIELD_STATIONTYPEID, FORM_FIELD_ENTITYID].includes(key);
                    if (!isIdField) {
                        value = ["startDate", "endDate"].includes(key) ? value.toDateString() : value;
                        if (props.entityForm.format.hasOwnProperty(key)) {
                            formattedEntry[props.entityForm.format[key]] = value;
                        }
                    }
                } catch (error) {
                    console.error(error)
                }
            })
            return formattedEntry;
        } catch (e) { }
    }


    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            fullScreen={fullScreen}
            open={props.openCompleteDialog}
        >
            <DialogTitle>
                {"Please confirm your changes"}
            </DialogTitle>
            <DialogContent>
                <EntityDisplay
                    entity={formatEntity(props.entityForm.entity)}
                    title={"New " + props.title}
                />
            </DialogContent>
            <DialogActions>
                <StyledOutlinedButton onClick={handleSubmit} autoFocus>
                    Create {props.title}
                </StyledOutlinedButton>
                <StyledOutlinedButton autoFocus onClick={handleGoBack}>
                    Go Back
                </StyledOutlinedButton>
            </DialogActions>
        </Dialog>
    )
}

AddCompleteDialog.propTypes = {
    entityForm: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    openCompleteDialog: PropTypes.bool.isRequired,
    setOpenCompleteDialog: PropTypes.func.isRequired,
    setOpenAlterDialog: PropTypes.func.isRequired,
};

export default AddCompleteDialog;