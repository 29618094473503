import * as React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { Toolbar } from '@mui/material';
import StyledListSelection from '../StyledInputs/StyledListSelection';
import StyledTextInput from '../StyledInputs/StyledTextInput';
import { filterSelectionSx } from '../../Utils/constants';
import StyledOutlinedButton from "../StyledButtons/StyledOutlinedButton";
import { FORM_FIELD_ENTITYID, FORM_FIELD_NAME } from '../../Utils/constants/formFieldCodes';
import { TITLE_SINGULAR_PROJECT } from '../../Utils/constants/titleCodes';
import { createSelectionList } from '../../Utils/helpers/listCreation';

const filterButton = { m: 1 };


const OrganizationFilter = (props) => {
    const { rows, setDataView, setHasData } = props;
    const [selectedProjectName, setSelectedProjectName] = useState([]);
    const [projectNames, setProjectNames] = useState([]);
    const [entityIds, setEntityIds] = useState([]);
    const [selectedEntityId, setSelectedEntityId] = useState([]);

    useEffect(() => {
        createSelectionList(rows, FORM_FIELD_NAME, setProjectNames);
        createSelectionList(rows, FORM_FIELD_ENTITYID, setEntityIds);
        setDataView(rows);
    }, [rows]);

    const filterData = () => {
        const dataView = rows.filter((el) => {
            const hasOrganization = selectedProjectName.length === 0 ? true : selectedProjectName.includes(el.name);
            const hasEntityId = selectedEntityId.length === 0 ? true : selectedEntityId.includes(el.entityId);

            return hasOrganization && hasEntityId;
        });
        createSelectionList(dataView, FORM_FIELD_NAME, setProjectNames);
        createSelectionList(dataView, FORM_FIELD_ENTITYID, setEntityIds);
        setDataView(dataView);
    }

    const refreshData = () => {
        setHasData(false);
    }

    return (
        <Box>
            <Toolbar>
                <StyledTextInput
                    sx={{ m: 1, width: 400 }}
                    label={"IDs"}
                    setSelection={setSelectedEntityId}
                    optionList={entityIds}
                />
                <StyledListSelection
                    sx={filterSelectionSx}
                    label={TITLE_SINGULAR_PROJECT}
                    setSelection={setSelectedProjectName}
                    value={selectedProjectName}
                    optionList={projectNames}
                />
            </Toolbar>
            <Toolbar>
                <StyledOutlinedButton
                    onClick={filterData}
                    sx={filterButton}
                >
                    Apply Filter
                </StyledOutlinedButton>
                <StyledOutlinedButton
                    onClick={refreshData}
                    sx={filterButton}
                >
                    Refresh Data
                </StyledOutlinedButton>
            </Toolbar>
        </Box>
    )
}


OrganizationFilter.propTypes = {
    rows: PropTypes.array.isRequired,
    setDataView: PropTypes.func.isRequired,
    setHasData: PropTypes.func.isRequired,
};




export default OrganizationFilter;