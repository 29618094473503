/**
 * Primary 500
 * https://coolors.co/palette/03045e-023e8a-0077b6-0096c7-00b4d8-48cae4-90e0ef-ade8f4-caf0f8
 */
export const PRIMARY_LIGHT_COLORS = {
    c50: "#FFFFFF",
    c100: "#CAF0F8",
    c200: "#ADE8F4",
    c300: "#90E0EF",
    c400: "#48CAE4",
    c500: "#00B4D8",
    c600: "#0096C7",
    c700: "#0077B6",
    c800: "#023E8A",
    c900: "#03045E",
}

export const SECONDARY_LIGHT_COLORS = {
    c50: "#FFEA00",
    c100: "#FFDD00",
    c200: "#FFD000",
    c300: "#FFC300",
    c400: "#FFB700",
    c500: "#FFAA00",
    c600: "#FFA200",
    c700: "#FF9500",
    c800: "#FF8800",
    c900: "#FF7B00",
}
