import axios from "axios";
import UserService from "./UserService";

const updateTokenOnLogin = (apiCall) => {
    if (UserService.isLoggedIn()) {
        UserService.updateToken(apiCall);
    } else {
        apiCall();
    }
}

const getAuthorizationHeader = () => {
    if (!!UserService.getToken()) {
        return {
            "X-Authorization": UserService.getToken(),
        };
    } else {
        return null;
    }
}

const logInformation = (response) => {
    console.log("[API Request] API Code: " + (!!response.data.status ? response.data.status : "success"));
}

const apiGetRequest = async (route, callback) => {
    try {
        const apiUrl = window._env_.REACT_APP_API_ADDRESS + route;
        const apiCall = () => {
            axios.get(apiUrl, { headers: getAuthorizationHeader() })
                .then(res => {
                    callback(res);
                })
                .catch(function (error) {

                });
        };
        updateTokenOnLogin(apiCall);
    } catch (error) {

    }
}

const apiPostRequest = async (route, data, callback = null) => {
    try {
        const apiUrl = window._env_.REACT_APP_API_ADDRESS + route;
        const apiCall = () => {
            axios.post(apiUrl, data, { headers: getAuthorizationHeader() })
                .then(res => {
                    if (!!callback) {
                        callback(res);
                    }
                    logInformation(res);
                    return res;
                })
                .catch(function (error) {

                });
        }
        updateTokenOnLogin(apiCall);
    } catch (error) {

    }
}

const apiPutRequest = async (route, data) => {
    try {
        const apiUrl = window._env_.REACT_APP_API_ADDRESS + route;
        const apiCall = () => {
            axios.put(apiUrl, data, { headers: getAuthorizationHeader() })
                .then(res => {
                    logInformation(res);
                })
                .catch(function (error) {

                });
        }
        updateTokenOnLogin(apiCall);
    } catch (error) {

    }
}

const apiDeleteRequest = async (route) => {
    try {
        const apiUrl = window._env_.REACT_APP_API_ADDRESS + route;
        const apiCall = () => {
            axios.delete(apiUrl, { headers: getAuthorizationHeader() })
                .then(res => {
                    logInformation(res);
                })
                .catch(function (error) {

                });
        }
        updateTokenOnLogin(apiCall);
    } catch (error) {

    }
}

const ApiService = {
    apiGetRequest,
    apiPostRequest,
    apiPutRequest,
    apiDeleteRequest,
}

export default ApiService;