import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select
} from '@mui/material';
import { ITEM_HEIGHT, ITEM_PADDING_TOP } from '../../Utils/constants';
import { Box } from '@mui/system';


const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const StyledObjectSelection = (props) => {
    
    const getNameById = (id) => {
        const element = props.optionList.find((el) => {
            return el.id === id;
        })
        return !!element ? element.name : "";
    }

    return (
        <FormControl sx={props.sx} error={props.isError}>
            <InputLabel>{props.label}</InputLabel>
            <Select
                data-testid="objectSelection"
                disabled={props.isDisabled}
                error={props.isError}
                value={props.value.id}
                onChange={(event) => props.setSelection(event)}
                input={<OutlinedInput label={props.label} />}
                renderValue={(selected) => {
                    return (
                        <Box>
                            {getNameById(selected)}
                        </Box>
                    )
                }}
                MenuProps={MenuProps}
            >
                <MenuItem disabled value="">
                    <em>{props.placeholder}</em>
                </MenuItem>
                {props.optionList.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                        <Checkbox checked={props.value.name === el.name} />
                        <ListItemText primary={el.name} secondary={el.id} />
                    </MenuItem>
                ))}
            </Select>
            {props.isError ? <FormHelperText>Please select a value!</FormHelperText> : null}
        </FormControl>
    )
}

StyledObjectSelection.propTypes = {
    label: PropTypes.string.isRequired,
    setSelection: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    optionList: PropTypes.array.isRequired,
    sx: PropTypes.object,
    isError: PropTypes.bool,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
};

StyledObjectSelection.defaultProps = {
    sx: {},
    isError: false,
    isDisabled: false,
    placeholder: "",
}

export default StyledObjectSelection;

