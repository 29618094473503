import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select
} from '@mui/material';
import { ITEM_HEIGHT, ITEM_PADDING_TOP } from '../../Utils/constants';


const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const handleSelected = (event, callback) => {
    const { target: { value }, } = event;
    callback(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
    );
};

const StyledListSelection = (props) => {
    const {
        sx,
        label,
        setSelection,
        value,
        optionList,
        multiSelect,
        isError,
        placeholder,
        isDisabled
    } = props;

    return (
        <FormControl sx={sx} error={isError}>
            <InputLabel id='StyledListSelectionLabel'>{label}</InputLabel>
            <Select
                labelId="StyledListSelectionLabel"
                id="StyledListSelection"
                data-testid="selection"
                disabled={isDisabled}
                error={isError}
                multiple={multiSelect}
                value={value}
                onChange={(event) => handleSelected(event, setSelection)}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <em>Placeholder</em>;
                    }
                    if (multiSelect) {
                        return selected.join(', ');
                    } else {
                        return selected;
                    }
                }}
                MenuProps={MenuProps}

            >
                <MenuItem disabled value="">
                    <em>{placeholder}</em>
                </MenuItem>
                {optionList.map((el, index) => (
                    <MenuItem key={index} value={el}>
                        <Checkbox checked={value.indexOf(el) > -1} />
                        <ListItemText primary={el} />
                    </MenuItem>
                ))}
            </Select>
            {isError ? <FormHelperText>Please select a value!</FormHelperText> : null}
        </FormControl>
    )
}


StyledListSelection.propTypes = {
    label: PropTypes.string.isRequired,
    setSelection: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    optionList: PropTypes.array.isRequired,
    sx: PropTypes.object,
    multiSelect: PropTypes.bool,
    isError: PropTypes.bool,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
};

StyledListSelection.defaultProps = {
    sx: {},
    multiSelect: true,
    isError: false,
    isDisabled: false,
    placeholder: "",
}

export default StyledListSelection;

