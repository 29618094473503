import { API_ATTRIBUTE_ENTITY_ID, API_ATTRIBUTE_URI } from "../constants/apiAttributeCodes";
import { ENDPOINT_ONBOARDING_SERVICES, ENDPOINT_STATION_TYPES } from "../constants/apiEndpointCodes";

/**
 * This is a class
 */
class ApiOnboardingService {

    dataModel = {
        [API_ATTRIBUTE_ENTITY_ID]: null,
        [API_ATTRIBUTE_URI]: null,
    };
    entityId = null;
    uri = null;
    stationType = null;
    postLink = null;
    resourceLink = null;

    addEntityId = (entityId) => {
        this.entityId = entityId;
        this.addResourceLink(entityId);
    }

    addUri = (uri) => {
        this.uri = new URL(uri);
    }

    addStationType = (stationType) => {
        this.stationType = stationType;
        this.addPostLink(stationType);
    }

    addPostLink = (stationType) => {
        this.postLink = ENDPOINT_STATION_TYPES + stationType + "/" + ENDPOINT_ONBOARDING_SERVICES;
    }

    /**
     * Link to a specific ressource. Needed for PUT and DELETE requests
     * @param {*} entityId 
     */
    addResourceLink = (entityId) => {
        this.resourceLink = ENDPOINT_ONBOARDING_SERVICES + entityId;
    }

    getPostLink = () => {
        return this.postLink;
    }

    getResourceLink = () => {
        return this.resourceLink;
    }

    getData = () => {
        this.dataModel[API_ATTRIBUTE_ENTITY_ID] = this.entityId;
        this.dataModel[API_ATTRIBUTE_URI] = this.uri;
        return this.dataModel;
    }

}

export default ApiOnboardingService;