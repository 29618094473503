import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';


const StyledTextInput = (props) => {
    const {
        sx, label, setSelection, optionList, multiSelect, isError
    } = props;

    const onTagsChange = (event, values) => {
        if (multiSelect) {
            setSelection(values);
        } else if (!multiSelect) {
            setSelection([values])
        } else {
            setSelection([])
        }
    };

    return (
        <Autocomplete
            multiple={multiSelect}
            options={optionList}
            onChange={onTagsChange}
            data-testid='autocomplete'
            sx={sx}
            renderInput={(params) => <TextField {...params}  
            data-testid="selectionElement" 
            error={isError} 
            label={label} />}
        />
    );
}

StyledTextInput.propTypes = {
    label: PropTypes.string.isRequired,
    setSelection: PropTypes.func.isRequired,
    optionList: PropTypes.array.isRequired,
    sx: PropTypes.object,
    multiSelect: PropTypes.bool,
    isError: PropTypes.bool,
};

StyledTextInput.defaultProps = {
    sx: {},
    multiSelect: true,
    isError: false
}

export default StyledTextInput;