import * as React from 'react';
import {
    FormControl,
    Box,
    DialogContentText,
    TextField,
} from "@mui/material";
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import StyledContainedButton from '../StyledButtons/StyledContainedButton';
import StyledOutlinedButton from '../StyledButtons/StyledOutlinedButton';
import UserService from '../../Services/UserService';
import StyledObjectSelection from '../StyledInputs/StyledObjectSelection';
import { createKeyValueList } from '../../Utils/helpers/listCreation';
import { createOnboardingServiceData } from '../../Utils/helpers/tableData';
import ApiService from '../../Services/ApiService';
import { filterSelectionSx } from '../../Utils/constants';
import { FORM_FIELD_ENTITYID, FORM_FIELD_NAME, FORM_FIELD_ONBOARDINGSERVICE, FORM_FIELD_ORGANIZATION, FORM_FIELD_ORGANIZATIONID, FORM_FIELD_STATION, FORM_FIELD_STATIONID } from '../../Utils/constants/formFieldCodes';
import { ENDPOINT_ONBOARDING_SERVICES, ENDPOINT_STATION_TYPES } from '../../Utils/constants/apiEndpointCodes';
import { TITLE_PLURAL_ONBOARDING_SERVICE, TITLE_SINGULAR_ONBOARDING, TITLE_SINGULAR_ONBOARDING_SERVICE, TITLE_SINGULAR_ORGANIZATION, TITLE_SINGULAR_STATION } from '../../Utils/constants/titleCodes';
import OnboardingCompleteDialog from './AlterDialogs/CompleteDialogs/OnboardingCompleteDialog';


const getOnboardingForm = () => {
    return {
        format: {
            onboardingServiceId: "Id",
            onboardingService: TITLE_SINGULAR_ONBOARDING_SERVICE,
            station: TITLE_SINGULAR_STATION,
            organization: TITLE_SINGULAR_ORGANIZATION,
            email: "Email",
        },
        entity: {
            onboardingServiceId: "",
            onboardingService: "",
            station: "",
            stationId: "",
            organization: "",
            organizationId: "",
            email: "",
        },
        errors: {
            onboardingServiceId: false,
            onboardingService: false,
            organization: false,
            station: false,
            email: false,
        }
    }
}

/**
 * Extracts necessary data of api call result.
 * @param {Array} res Result of api call
 * @param {func} setState Setter function of component state
 */
const getOnboardingServiceData = (res, setState) => {
    try {
        let rows = res.data["entity-list"].map((el) => {
            const stationType = el["link-list"].find((el) => el.name === "station-type").preview;
            const stationTypeId = el["link-list"].find((el) => el.name === "station-type").uri;
            return createOnboardingServiceData(
                el.id.replace(window._env_.REACT_APP_API_PREFIX + ENDPOINT_ONBOARDING_SERVICES, ""),
                el.uri.replace(window._env_.REACT_APP_API_PREFIX + ENDPOINT_STATION_TYPES, ""),
                stationType,
                stationTypeId.replace(window._env_.REACT_APP_API_PREFIX + ENDPOINT_STATION_TYPES, ""),
            );
        });
        createKeyValueList(rows, FORM_FIELD_ONBOARDINGSERVICE, FORM_FIELD_ENTITYID, setState)
    } catch (error) {
        console.error("No api result for station component");
        setState([]);
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} station 
 * @param {*} stationId 
 * @param {*} organizationId 
 * @param {*} setStateFunc 
 */
export const createStationOrganizationList = (data, station, stationId, organizationId, setStateFunc) => {
    let uniques = {};
    let elementList = data.map((el) => {
        if (!uniques[el[stationId]]) {
            uniques[el[stationId]] = "None";
            return {
                name: el[station],
                id: el[stationId],
                organizationId: el[organizationId]
            };
        }
    });
    elementList.sort((a, b) => a.name.localeCompare(b.name));
    elementList.filter((el) => el !== undefined);
    setStateFunc([...new Set(elementList.filter((el) => el !== undefined))]);
}

const OBJECT_SELECTIONS = [FORM_FIELD_STATION, FORM_FIELD_ORGANIZATION, FORM_FIELD_ONBOARDINGSERVICE];


const StationOnboarding = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [entityForm, setEntityForm] = useState(getOnboardingForm());
    const [onboardingServices, setOnboardingServices] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [stations, setStations] = useState([]);
    const [stationView, setStationView] = useState([]);
    const [stationsDisabled, setStationsDisabled] = useState(true);
    const [parsedPassword, setParsedPassword] = useState(null);

    const handleClose = () => {
        setEntityForm(getOnboardingForm());
        setOpen(false);
        setParsedPassword(null);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        ApiService.apiGetRequest(ENDPOINT_ONBOARDING_SERVICES, (res) => getOnboardingServiceData(res, setOnboardingServices));
        createKeyValueList(props.stationRows, FORM_FIELD_ORGANIZATION, FORM_FIELD_ORGANIZATIONID, setOrganizations);
        createStationOrganizationList(props.stationRows, FORM_FIELD_NAME, FORM_FIELD_ENTITYID, FORM_FIELD_ORGANIZATIONID, setStations);
        createStationOrganizationList(props.stationRows, FORM_FIELD_NAME, FORM_FIELD_ENTITYID, FORM_FIELD_ORGANIZATIONID, setStationView);
    }, [props.stationRows]);

    const getNameById = (id, idList) => {
        const element = idList.find((el) => {
            return el.id === id;
        })
        return !!element ? element.name : "";
    }

    const handleFormChange = (event, formLocation, idList) => {
        let items = { ...entityForm };
        switch (event.constructor) {
            case String:
                items.entity[formLocation] = event;
                break;
            default:
                if (OBJECT_SELECTIONS.includes(formLocation)) {
                    items.entity[formLocation] = getNameById(event.target.value, idList);
                    items.entity[formLocation + "Id"] = event.target.value;
                    if (formLocation === FORM_FIELD_ORGANIZATION) {
                        const filteredStations = stations.filter((el) => {
                            return el.organizationId === event.target.value
                        });
                        setStationView(filteredStations);
                        items.entity[FORM_FIELD_STATION] = "";
                        items.entity[FORM_FIELD_STATIONID] = "";
                        setStationsDisabled(false);
                    }
                }
                break;
        }
        setEntityForm(items);
    }

    /**
     * Returns true if all entries of an object are false.
     * @param {*} errorList 
     * @returns 
     */
    const isValid = (errorList) => {
        return !Object.values(errorList).find(el => el);
    }

    const handleSubmit = () => {
        let items = { ...entityForm };
        Object.entries(items.entity).map(([key, value]) => {
            // Check if values are empty
            if (value.toString().trim() === "") {
                items.errors[key] = true;
            } else {
                items.errors[key] = false;
            }
        });
        const isValidEntry = isValid(items.errors);
        setIsSuccessful(isValidEntry);
        setEntityForm(items);
    }

    return (
        <Box sx={{ m: 1 }}>
            <StyledContainedButton
                disabled={UserService.isLoggedIn() ? false : true}
                onClick={handleClickOpen}
            >
                Start Onboarding
            </StyledContainedButton>
            <Dialog
                fullWidth={true}
                maxWidth={"xs"}
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    Onboarding of a station
                </DialogTitle>
                <DialogContent
                    sx={{
                        alignContent: "center",
                    }}
                >
                    {!!parsedPassword ?
                        <DialogContentText>
                            Here is your password:
                            <br />
                            {parsedPassword}
                        </DialogContentText>
                        :
                        <Box>
                            <DialogContentText sx={{}}>
                                The onboarding process allows you to start the supported configuration of your new Personal Health Train station.
                                <br />
                                Please, specify the station that should be configured and an E-Mail address to which information about the next steps will be sent.
                                Please take care, this address is already working since the Station Registry will not check its availability.
                            </DialogContentText>
                            <FormControl
                                sx={{
                                    width: "100%"
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <StyledObjectSelection
                                        sx={{
                                            m: 1,
                                            width: "100%"
                                        }}
                                        label={TITLE_PLURAL_ONBOARDING_SERVICE}
                                        setSelection={(val) => handleFormChange(val, FORM_FIELD_ONBOARDINGSERVICE, onboardingServices)}
                                        value={{ name: entityForm.entity.onboardingService, id: entityForm.entity.onboardingServiceId }}
                                        optionList={onboardingServices}
                                        isError={entityForm.errors.onboardingService}
                                    />
                                    <StyledObjectSelection
                                        sx={filterSelectionSx}
                                        label={TITLE_SINGULAR_ORGANIZATION}
                                        setSelection={(val) => handleFormChange(val, FORM_FIELD_ORGANIZATION, organizations)}
                                        value={{ name: entityForm.entity.organization, id: entityForm.entity.organizationId }}
                                        optionList={organizations}
                                        isError={entityForm.errors.organization}
                                    />
                                    <StyledObjectSelection
                                        sx={filterSelectionSx}
                                        isDisabled={stationsDisabled}
                                        label={TITLE_SINGULAR_STATION}
                                        setSelection={(val) => handleFormChange(val, FORM_FIELD_STATION, stations)}
                                        value={
                                            { name: entityForm.entity.station, id: entityForm.entity.stationId }
                                        }
                                        optionList={stationView}
                                        isError={entityForm.errors.organization}
                                    />
                                    <TextField
                                        sx={filterSelectionSx}
                                        error={entityForm.errors.onboardingService}
                                        helperText={entityForm.errors.onboardingService ? "Please fill in an email" : null}
                                        onChange={(event) => handleFormChange(event.target.value, "email")}
                                        label="Email"
                                        variant="outlined"
                                    />
                                </Box>
                            </FormControl>
                        </Box>

                    }
                </DialogContent>
                <DialogActions>
                    {
                        !!parsedPassword ?
                            <Box>
                                <StyledOutlinedButton autoFocus onClick={() =>  navigator.clipboard.writeText(parsedPassword)}>
                                    Copy to Clipboard
                                </StyledOutlinedButton>
                            </Box>
                            :
                            <Box>
                                <StyledOutlinedButton autoFocus onClick={handleSubmit}>
                                    Start Onboarding
                                </StyledOutlinedButton>
                                <StyledOutlinedButton autoFocus onClick={handleClose}>
                                    Cancel
                                </StyledOutlinedButton>
                            </Box>
                    }
                </DialogActions>
            </Dialog>
            <OnboardingCompleteDialog
                title={TITLE_SINGULAR_ONBOARDING}
                entityForm={entityForm}
                openCompleteDialog={isSuccessful}
                setOpenCompleteDialog={setIsSuccessful}
                setParsedPassword={setParsedPassword}
            />
        </Box>
    )
}

StationOnboarding.propTypes = {
    stationRows: PropTypes.array.isRequired,
};

StationOnboarding.defaultProps = {
}


export default StationOnboarding;