import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Toolbar,
    Box,
    TextField
} from "@mui/material";
import PropTypes from 'prop-types';
import { alterBoxSx } from '../../Utils/styles/sxStyles';
import AlterSectionsAdd from '../../Components/AlterSections/AlterDialogs/AlterSectionsAdd';
import AlterSectionsEdit from '../../Components/AlterSections/AlterDialogs/AlterSectionsEdit';
import { ACCESS_LEVELS, filterSelectionSx } from "../../Utils/constants";
import StyledObjectSelection from '../StyledInputs/StyledObjectSelection';
import StyledListSelection from '../StyledInputs/StyledListSelection';
import StyledTextInput from '../StyledInputs/StyledTextInput';
import { createKeyValueList, createSelectionList } from '../../Utils/helpers/listCreation';
import StationOnboarding from '../../Components/AlterSections/StationOnboarding';
import { FORM_FIELD_ENTITYID, FORM_FIELD_NAME, FORM_FIELD_ORGANIZATION, FORM_FIELD_STATIONTYPE } from '../../Utils/constants/formFieldCodes';
import { TITLE_SINGULAR_ORGANIZATION, TITLE_SINGULAR_STATION, TITLE_SINGULAR_STATION_TYPE } from '../../Utils/constants/titleCodes';

const getDefaultForm = () => {
    return {
        format: {
            entityId: "Id",
            name: "Name",
            organization: TITLE_SINGULAR_ORGANIZATION,
            accessLevel: "Access Level",
            stationType: TITLE_SINGULAR_STATION_TYPE,
        },
        entity: {
            entityId: "",
            organizationId: "",
            stationTypeId: "",
            name: "",
            organization: "",
            accessLevel: [], // StyledListSelection has to be initialized with an empty array
            stationType: "",
            onboarding: "",
        },
        errors: {
            entityId: false,
            name: false,
            organization: false,
            accessLevel: false,
            stationType: false,
        }
    }
}

const StationAlter = (props) => {

    const [entityIds, setEntityIds] = useState([]);
    const [stationTypes, setStationTypes] = useState([]);
    const [organizations, setOrganizations] = useState([]);

    useEffect(() => {
        createSelectionList(props.stationData, FORM_FIELD_ENTITYID, setEntityIds);
        createKeyValueList(props.stationTypeData, FORM_FIELD_NAME, FORM_FIELD_ENTITYID, setStationTypes);
        createKeyValueList(props.organizationData, FORM_FIELD_NAME, FORM_FIELD_ENTITYID, setOrganizations);
    }, [props.stationData]);

    const addInputs = (entityForm, handleFormChange) => {
        return (
            <Box>
                <TextField
                    data-testid="textInput"
                    error={entityForm.errors.name}
                    helperText={entityForm.errors.name ? "Please fill in a name" : null}
                    sx={filterSelectionSx}
                    onChange={(event) => handleFormChange(event.target.value, FORM_FIELD_NAME)}
                    label="Station Name"
                    variant="outlined"
                />
                <StyledObjectSelection
                    sx={filterSelectionSx}
                    label={TITLE_SINGULAR_ORGANIZATION}
                    setSelection={(val) => handleFormChange(val, FORM_FIELD_ORGANIZATION, organizations)}
                    value={{ name: entityForm.entity.organization, id: entityForm.entity.organizationId }}
                    optionList={organizations}
                    isError={entityForm.errors.organization}
                />
                <StyledObjectSelection
                    sx={filterSelectionSx}
                    label={TITLE_SINGULAR_STATION_TYPE}
                    setSelection={(val) => handleFormChange(val, FORM_FIELD_STATIONTYPE, stationTypes)}
                    value={{ name: entityForm.entity.stationType, id: entityForm.entity.stationTypeId }}
                    optionList={stationTypes}
                    isError={entityForm.errors.stationType}
                />
                <StyledListSelection
                    sx={filterSelectionSx}
                    label={"Access Level"}
                    setSelection={(val) => handleFormChange(val, "accessLevel")}
                    value={entityForm.entity.accessLevel}
                    optionList={ACCESS_LEVELS}
                    multiSelect={false}
                    isError={entityForm.errors.accessLevel}
                />
            </Box>
        )
    }


    const editInputs = (entityForm, selectEntity, isDisabled, handleFormChange) => {
        return (
            <Box>
                <StyledTextInput
                    sx={{ m: 1, width: "80%" }}
                    label={"IDs"}
                    setSelection={selectEntity}
                    optionList={entityIds}
                    multiSelect={false}
                    isError={entityForm.errors.entityId}
                />
                <TextField
                    data-testid="textInput"
                    sx={filterSelectionSx}
                    error={entityForm.errors.name}
                    label={"Station Name"}
                    value={entityForm.entity.name}
                    onChange={(event) => handleFormChange(event.target.value, FORM_FIELD_NAME)}
                    disabled={isDisabled}
                />
                <StyledObjectSelection
                    sx={filterSelectionSx}
                    label={TITLE_SINGULAR_ORGANIZATION}
                    setSelection={(val) => handleFormChange(val, FORM_FIELD_ORGANIZATION, organizations)}
                    value={{ name: entityForm.entity.organization, id: entityForm.entity.organizationId }}
                    optionList={organizations}
                    isError={entityForm.errors.organization}
                    isDisabled={isDisabled}
                />
                <StyledObjectSelection
                    sx={filterSelectionSx}
                    label={TITLE_SINGULAR_STATION_TYPE}
                    setSelection={(val) => handleFormChange(val, FORM_FIELD_STATIONTYPE, stationTypes)}
                    value={{ name: entityForm.entity.stationType, id: entityForm.entity.stationTypeId }}
                    optionList={stationTypes}
                    isError={entityForm.errors.stationType}
                    isDisabled={isDisabled}
                />
                <StyledListSelection
                    sx={filterSelectionSx}
                    label={"Access Level"}
                    setSelection={(val) => handleFormChange(val, "accessLevel")}
                    value={entityForm.entity.accessLevel}
                    optionList={ACCESS_LEVELS}
                    multiSelect={false}
                    isError={entityForm.errors.accessLevel}
                    isDisabled={isDisabled}
                />
            </Box>
        )
    }

    return (
        <Box sx={alterBoxSx}>
            <Toolbar>
                <AlterSectionsAdd
                    entityName={TITLE_SINGULAR_STATION}
                    alterInputs={addInputs}
                    defaultForm={getDefaultForm}
                />
                <AlterSectionsEdit
                    rows={props.stationData}
                    entityName={TITLE_SINGULAR_STATION}
                    alterInputs={editInputs}
                    defaultForm={getDefaultForm}
                />
                <StationOnboarding
                     stationRows={props.stationData}
                />
            </Toolbar>
        </Box>
    )
}

StationAlter.propTypes = {
    stationData: PropTypes.array.isRequired,
    stationTypeData: PropTypes.array.isRequired,
    organizationData: PropTypes.array.isRequired,
    setHasData: PropTypes.func.isRequired
};



export default StationAlter;