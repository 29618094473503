export const TITLE_PLURAL_STATION = "Stations";
export const TITLE_PLURAL_ORGANIZATION = "Organizations";
export const TITLE_PLURAL_STATION_TYPE = "Station Types";
export const TITLE_PLURAL_PROJECT = "Projects";
export const TITLE_PLURAL_ONBOARDING_SERVICE = "Onboarding Services";
export const TITLE_PLURAL_ONBOARDING = "Onboardings";

export const TITLE_SINGULAR_STATION = "Station";
export const TITLE_SINGULAR_ORGANIZATION = "Organization";
export const TITLE_SINGULAR_STATION_TYPE = "Station Type";
export const TITLE_SINGULAR_PROJECT = "Project";
export const TITLE_SINGULAR_ONBOARDING_SERVICE = "Onboarding Service";
export const TITLE_SINGULAR_ONBOARDING = "Onboarding";
