import * as React from 'react';
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import { DRAWERWIDTH, NAVBARHEIGHT } from "../Utils/constants";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const PageWrapper = (props) => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const MARGIN_LEFT = smallScreen ? 20 : DRAWERWIDTH;
    const MARGIN_TOP = smallScreen ? 60 : NAVBARHEIGHT;

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column"
        }}>
            <Sidebar title={props.title} />
            <Box sx={{ marginLeft: `${MARGIN_LEFT}px` }}>
                {
                    !smallScreen && <Navbar
                        icon={props.icon}
                        title={props.title}
                    />
                }
                <Box sx={{ marginTop: `${MARGIN_TOP}px` }}>
                    {props.children}
                </Box>
            </Box>
        </Box>
    )
}

PageWrapper.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired
};




export default PageWrapper;
