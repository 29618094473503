
export const STATIONHEAD = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'organization',
        numeric: false,
        disablePadding: false,
        label: 'Organization',
    },
    {
        id: 'accessLevel',
        numeric: false,
        disablePadding: false,
        label: 'Access Level',
    },
    {
        id: 'stationType',
        numeric: false,
        disablePadding: false,
        label: 'Station Type',
    },
    {
        id: 'onboarding',
        numeric: false,
        disablePadding: false,
        label: 'Onboarding Status',
    },
];

export const ORGANIZATIONHEAD = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    }
];

export const STATIONTYPESHEAD = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description',
    },
];

export const PROJECTSHEADER = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'startDate',
        numeric: false,
        disablePadding: false,
        label: 'Start Date',
    },
    {
        id: 'endDate',
        numeric: false,
        disablePadding: false,
        label: 'End Date',
    },
];

export const ONBOARDINGHEAD = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
    {
        id: 'stationType',
        numeric: false,
        disablePadding: false,
        label: 'Station Type',
    },
    {
        id: 'onboardingService',
        numeric: false,
        disablePadding: false,
        label: 'Onboarding Service',
    }
];