import { ENDPOINT_ONBOARDING_SERVICES, ENDPOINT_STATION_ONBOARDING } from "../constants/apiEndpointCodes";
import { 
    API_ATTRIBUTE_LINK_LIST,
    API_ATTRIBUTE_EMAIL,
    API_ATTRIBUTE_STATION
} from "../constants/apiAttributeCodes";

/**
 * This is a class
 */
class ApiStationOnboarding {

    dataModel = {
        [API_ATTRIBUTE_LINK_LIST]: [
            { name: API_ATTRIBUTE_STATION, uri: null },
            { name: API_ATTRIBUTE_EMAIL, uri: null },
        ]
    };
    /**Id of onboarding service */
    entityId = null;
    postLink = null;
    stationId = null;
    email = null;

    addEntityId = (entityId) => {
        this.entityId = entityId;
        this.addPostLink(entityId);
    }

    addStation = (stationId) => {
        this.stationId = stationId;
    }

    addEmail = (email) => {
        this.email = email;
    }

    addPostLink = (entityId) => {
        this.postLink = ENDPOINT_ONBOARDING_SERVICES + entityId + "/" + ENDPOINT_STATION_ONBOARDING;
    }

    getPostLink = () => {
        return this.postLink;
    }

    getData = () => {
        this.dataModel[API_ATTRIBUTE_EMAIL] = this.email;
        this.dataModel[API_ATTRIBUTE_LINK_LIST].map((el) => {
            if (el.name === API_ATTRIBUTE_STATION) {
                el.uri = this.stationId;
            } else if (el.name === API_ATTRIBUTE_EMAIL) {
                el.uri = this.email;
            }
        });
        return this.dataModel;
    }

}

export default ApiStationOnboarding;