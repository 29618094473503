import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import UserService from './Services/UserService';
import HttpService from './Services/HttpService';
import App from './App';
import configureSuccessfulApiStore from './Services/store-inits/successfulApi-store';

configureSuccessfulApiStore();

const root = ReactDOM.createRoot(document.getElementById('root'));
const renderApp = () => {
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
};

const renderErrorApp = () => {
    root.render(
        <React.StrictMode>
            <h1>Keycloak is not loaded, please contact support!</h1>
        </React.StrictMode>
    );
};

UserService.initKeycloak(renderApp, renderErrorApp);
HttpService.configure();

// renderApp();