import * as React from 'react';
import {
    Toolbar,
    Box,
    TextField
} from "@mui/material";
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AlterSectionsAdd from './AlterDialogs/AlterSectionsAdd';
import AlterSectionsEdit from './AlterDialogs/AlterSectionsEdit';
import StyledTextInput from '../StyledInputs/StyledTextInput';
import StyledObjectSelection from '../StyledInputs/StyledObjectSelection';
import { alterBoxSx } from '../../Utils/styles/sxStyles';
import { filterSelectionSx } from '../../Utils/constants';
import {
    FORM_FIELD_ENTITYID,
    FORM_FIELD_NAME,
    FORM_FIELD_ONBOARDINGSERVICE, 
    FORM_FIELD_STATIONTYPE
} from '../../Utils/constants/formFieldCodes';
import {
    TITLE_SINGULAR_ONBOARDING_SERVICE,
    TITLE_SINGULAR_STATION_TYPE,
} from '../../Utils/constants/titleCodes';
import { createKeyValueList, createSelectionList } from '../../Utils/helpers/listCreation';

const getDefaultForm = () => {
    return {
        format: {
            entityId: "Id",
            onboardingService: TITLE_SINGULAR_ONBOARDING_SERVICE,
            stationType: TITLE_SINGULAR_STATION_TYPE,
        },
        entity: {
            entityId: "",
            onboardingService: "",
            stationTypeId: "",
            stationType: "",
        },
        errors: {
            entityId: false,
            onboardingService: false,
            stationType: false,
        }
    }
}

const OnboardingAlter = (props) => {

    const [entityIds, setEntityIds] = useState([]);
    const [stationTypes, setStationTypes] = useState([]);

    useEffect(() => {
        createSelectionList(props.rows, FORM_FIELD_ENTITYID, setEntityIds);
        createKeyValueList(props.stationTypeRows, FORM_FIELD_NAME, FORM_FIELD_ENTITYID, setStationTypes);
    }, [props.rows]);

    const addInputs = (entityForm, handleFormChange) => {
        return (
            <Box>
                <TextField
                    error={entityForm.errors.uri}
                    helperText={entityForm.errors.uri ? "Please fill in a uri" : null}
                    sx={filterSelectionSx}
                    onChange={(event) => handleFormChange(event.target.value, FORM_FIELD_ONBOARDINGSERVICE)}
                    label="Organization URI"
                    variant="outlined"
                />
                <StyledObjectSelection
                    sx={filterSelectionSx}
                    label={TITLE_SINGULAR_STATION_TYPE}
                    setSelection={(val) => handleFormChange(val, FORM_FIELD_STATIONTYPE, stationTypes)}
                    value={{ name: entityForm.entity.stationType, id: entityForm.entity.stationTypeId }}
                    optionList={stationTypes}
                    isError={entityForm.errors.stationType}
                />
            </Box>
        )
    }

    const editInputs = (entityForm, selectEntity, isDisabled, handleFormChange) => {
        return (
            <Box>
                <StyledTextInput
                    sx={{ m: 1, width: "80%" }}
                    label={"IDs"}
                    setSelection={selectEntity}
                    optionList={entityIds}
                    multiSelect={false}
                    isError={entityForm.errors.entityId}
                />
                <TextField
                    sx={filterSelectionSx}
                    error={entityForm.errors.onboardingService}
                    helperText={entityForm.errors.onboardingService ? "Please fill in a onboardingService" : null}
                    label={TITLE_SINGULAR_ONBOARDING_SERVICE}
                    value={entityForm.entity.onboardingService}
                    onChange={(event) => handleFormChange(event.target.value, FORM_FIELD_ONBOARDINGSERVICE)}
                    disabled={isDisabled}
                />
                <StyledObjectSelection
                    sx={filterSelectionSx}
                    label={TITLE_SINGULAR_STATION_TYPE}
                    setSelection={(val) => handleFormChange(val, FORM_FIELD_STATIONTYPE, stationTypes)}
                    value={{ name: entityForm.entity.stationType, id: entityForm.entity.stationTypeId }}
                    optionList={stationTypes}
                    isError={entityForm.errors.stationType}
                    isDisabled={isDisabled}
                />
            </Box>
        )
    }

    return (
        <Box sx={alterBoxSx}>
            <Toolbar>
                <AlterSectionsAdd
                    entityName={TITLE_SINGULAR_ONBOARDING_SERVICE}
                    alterInputs={addInputs}
                    defaultForm={getDefaultForm}
                />
                <AlterSectionsEdit
                    rows={props.rows}
                    entityName={TITLE_SINGULAR_ONBOARDING_SERVICE}
                    alterInputs={editInputs}
                    defaultForm={getDefaultForm}
                />
            </Toolbar>
        </Box>
    )
}

OnboardingAlter.propTypes = {
    rows: PropTypes.array.isRequired,
    stationTypeRows: PropTypes.array.isRequired,
};



export default OnboardingAlter;