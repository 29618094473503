import * as React from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from "@mui/material";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { AccountCircle, ContentCut } from '@mui/icons-material';


const entityBoxStyle = {
    border: 1,
    borderColor: "lightgray",
    borderRadius: 2,

}

const boxTitleStyle = {
    textAlign: "center",
    m: 2,
    width: "100%",
    // backgroundColor: "green",
}

const listStyle = {
    // backgroundColor: "blue",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
}

const listItemStyle = {
    width: "90%",
    // borderRadius: 3,
    // backgroundColor: "#bdbdbd" 
}

/**
 * Used to display entities (stations, organizations,...) in a card-like form with information in list form. 
 * @param {*} props 
 * @returns 
 */
const EntityDisplay = (props) => {
    const { entity, title } = props;
    return (
        <Box sx={entityBoxStyle}>
            <Box sx={boxTitleStyle}>
                <Typography variant='h5'>
                    {title}
                </Typography>
            </Box>
            <List dense={true} sx={listStyle}>
                {Object.entries(entity).map(([key, value]) => {
                    return (
                        <ListItem sx={listItemStyle} key={key} data-testid="entity-entry">
                            <ListItemIcon>
                                <AccountCircle sx={{ fontSize: 35 }} />
                            </ListItemIcon>
                            <ListItemText
                                sx={{ ml: -1 }}
                                primary={<Box sx={{ fontSize: 10 }}>{key}</Box>}
                                secondary={value}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    )
}

EntityDisplay.propTypes = {
    entity: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
};

export default EntityDisplay;