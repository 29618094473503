import { 
    createOrganizationData, 
    createStationData,
    createOnboardingServiceData,
    createStationTypeData,
    createProjectData,
} from './tableData';
import { 
    ENDPOINT_STATION_TYPES, 
    ENDPOINT_ORGANIZATIONS, 
    ENDPOINT_ONBOARDING_SERVICES,
    ENDPOINT_PROJECTS,
    ENDPOINT_STATIONS
} from "../constants/apiEndpointCodes";
import {
    API_ATTRIBUTE_ORGANIZATION,
    API_ATTRIBUTE_STATION_TYPE,
    API_ATTRIBUTE_LINK_LIST,
    API_ATTRIBUTE_ACCESS_LEVEL,
    API_ATTRIBUTE_ONBOARDING,
    API_ATTRIBUTE_END_DATE,
    API_ATTRIBUTE_START_DATE
} from "../constants/apiAttributeCodes";


/**
 * Transforms data from stations/ API get call 
 * and sets state of component according to it.
 * Returns object list in following format:
 * 
 * [
 *      {
 *       entityId ,
 *       organizationId,
 *       stationTypeId,
 *       name,
 *       organization,
 *       accessLevel,
 *       stationType,
 *       onboarding
 *      },...
 * ]
 * @param {Array} res Result of api call
 * @param {func} setState Setter function of component state
 */
export const transformStationData = (res, setState) => {
    try {
        let rows = res.data["entity-list"].map((el) => {
            const organizationId = el[API_ATTRIBUTE_LINK_LIST].find((el) => el.name === API_ATTRIBUTE_ORGANIZATION).uri;
            const organizationName = el[API_ATTRIBUTE_LINK_LIST].find((el) => el.name === API_ATTRIBUTE_ORGANIZATION).preview;
            const stationTypeId = el[API_ATTRIBUTE_LINK_LIST].find((el) => el.name === API_ATTRIBUTE_STATION_TYPE).uri;
            const stationType = el[API_ATTRIBUTE_LINK_LIST].find((el) => el.name === API_ATTRIBUTE_STATION_TYPE).preview;
            return createStationData(
                el.id.replace(window._env_.REACT_APP_API_PREFIX + ENDPOINT_STATIONS, ""),
                organizationId.replace(window._env_.REACT_APP_API_PREFIX + ENDPOINT_ORGANIZATIONS, ""),
                stationTypeId.replace(window._env_.REACT_APP_API_PREFIX + ENDPOINT_STATION_TYPES, ""),
                el.name,
                organizationName,
                el[API_ATTRIBUTE_ACCESS_LEVEL],
                stationType,
                el[API_ATTRIBUTE_ONBOARDING]
            )
        });
        setState(rows);
    } catch (error) {
        console.error("No api result for Stations");
        setState([]);
    }
}

/**
 * Transforms data from organizations/ API get call 
 * and sets state of component according to it.
 * Returns object list in following format:
 * 
 * 
 * [
 *      {
 *       entityId ,
 *       name,
 *      },...
 * ]
 * 
 * @param {Array} res Result of api call
 * @param {func} setState Setter function of component state
 */
export const transformOrganizationData = (res, setState) => {
    try {
        let rows = res.data["entity-list"].map((el) => {
            return createOrganizationData(
                el.id.replace(window._env_.REACT_APP_API_PREFIX + ENDPOINT_ORGANIZATIONS, ""),
                el.name
            );
        });
        setState(rows);
    } catch (error) {
        console.error("No api result for Organizations");
        setState([]);
    }
}


/**
 * 
 * Transforms data from onboarding-services/ API get call 
 * and sets state of component according to it.
 * Returns object list in following format:
 * 
 * 
 * [
 *      {
 *       entityId ,
 *       onboardingService,
 *       stationType,
 *       stationTypeId
 *      },...
 * ]
 *  * @param {Array} res Result of api call
 * @param {func} setState Setter function of component state
 */
export const transformOnboardingServiceData = (res, setState) => {
    try {
        let rows = res.data["entity-list"].map((el) => {
            const stationType = el[API_ATTRIBUTE_LINK_LIST].find((el) => el.name === API_ATTRIBUTE_STATION_TYPE).preview;
            const stationTypeId = el[API_ATTRIBUTE_LINK_LIST].find((el) => el.name === API_ATTRIBUTE_STATION_TYPE).uri;
            return createOnboardingServiceData(
                el.id.replace(window._env_.REACT_APP_API_PREFIX + ENDPOINT_ONBOARDING_SERVICES, ""),
                el.uri,
                stationType,
                stationTypeId.replace(window._env_.REACT_APP_API_PREFIX + ENDPOINT_STATION_TYPES, ""),
            );
        });
        setState(rows);
    } catch (error) {
        console.error("No api result for Onboarding-Services");
        setState([]);
    }
}


/**
 * 
 * Transforms data from station-types/ API get call 
 * and sets state of component according to it.
 * Returns object list in following format:
 * 
 * [
 *      {
 *       entityId ,
 *       name,
 *       description
 *      },...
 * ]
 * 
 * @param {Array} res Result of api call
 * @param {func} setState Setter function of component state
 */
export const transformStationTypeData = (res, setState) => {
    try {
        let rows = res.data["entity-list"].map((el) => {
            return createStationTypeData(
                el.id.replace(window._env_.REACT_APP_API_PREFIX + ENDPOINT_STATION_TYPES, ""),
                el.name,
                el.description
            );
        });
        setState(rows);
    } catch (error) {
        console.error("No api result for Station-Types");
        setState([]);
    }
}

/**
 * 
 * Transforms data from station-types/ API get call 
 * and sets state of component according to it.
 * Returns object list in following format:
 * 
 * [
 *      {
 *       entityId ,
 *       name,
 *       startDate,
 *       endDate
 *      },...
 * ]
 * @param {Array} res Result of api call
 * @param {func} setState Setter function of component state
 */
export const transformProjectData = (res, setState) => {
    try {
        let rows = res.data["entity-list"].map((el) => {
            return createProjectData(
                el.id.replace(window._env_.REACT_APP_API_PREFIX + ENDPOINT_PROJECTS, ""),
                el.name,
                el[API_ATTRIBUTE_START_DATE],
                el[API_ATTRIBUTE_END_DATE]
            );
        });
        setState(rows);
    } catch (error) {
        console.error("No api result for Projects");
        setState([]);
    }
}
