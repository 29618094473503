import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { DRAWERWIDTH, NAVBARHEIGHT } from '../Utils/constants';
import {
    Link as RouterLink
} from "react-router-dom";
import LoginButton from "./LoginButton";
import StationIcon from './PageIcons/StationIcon';
import OrganizationIcon from "./PageIcons/OrganizationIcon";
import OnboardingIcon from "./PageIcons/OnboardingIcon";
import ProjectIcon from "./PageIcons/ProjectIcon";
import StationTypeIcon from "./PageIcons/StationTypeIcon";
import { PRIMARY_LIGHT_COLORS } from '../Utils/styles/colorSchemes';
import {
    ROUTES_BLANK,
    ROUTES_STATION,
    ROUTES_PROJECTS,
    ROUTES_ORGANIZATION,
    ROUTES_STATIONTYPES,
    ROUTES_ONBOARDINGSERVICES
} from "../Utils/constants/routeCodes";
import {
    TITLE_PLURAL_ONBOARDING_SERVICE,
    TITLE_PLURAL_ORGANIZATION,
    TITLE_PLURAL_PROJECT,
    TITLE_PLURAL_STATION,
    TITLE_PLURAL_STATION_TYPE
} from '../Utils/constants/titleCodes';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Menu, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';

const paperProps = {
    sx: {
        backgroundColor: PRIMARY_LIGHT_COLORS.c500,
        color: "white",
    }
}

const drawerSx = {
    width: DRAWERWIDTH,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: DRAWERWIDTH,
        boxSizing: 'border-box',
    },
}

const ITEM_HEIGHT = 48;

const Sidebar = (props) => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const iconSx = {
        fontSize: 40,
        color: smallScreen ? PRIMARY_LIGHT_COLORS.c600 : "#fff"
    }

    const routeItems = [
        { title: TITLE_PLURAL_STATION, route: ROUTES_BLANK + ROUTES_STATION, icon: <StationIcon sx={iconSx} /> },
        { title: TITLE_PLURAL_STATION_TYPE, route: ROUTES_BLANK + ROUTES_STATIONTYPES, icon: <StationTypeIcon sx={iconSx} /> },
        { title: TITLE_PLURAL_ORGANIZATION, route: ROUTES_BLANK + ROUTES_ORGANIZATION, icon: <OrganizationIcon sx={iconSx} /> },
        { title: TITLE_PLURAL_PROJECT, route: ROUTES_BLANK + ROUTES_PROJECTS, icon: <ProjectIcon sx={iconSx} /> },
        { title: TITLE_PLURAL_ONBOARDING_SERVICE, route: ROUTES_BLANK + ROUTES_ONBOARDINGSERVICES, icon: <OnboardingIcon sx={iconSx} /> },
    ]

    if (smallScreen) {
        return (
            <Box>
                <AppBar
                    sx={{ backgroundColor: PRIMARY_LIGHT_COLORS.c500 }}
                >
                    <Toolbar>
                        <MenuIcon
                            aria-label="more"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </MenuIcon>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            <List>
                                {routeItems.map((routeItem, index) => (
                                    <ListItem button key={index} component={RouterLink} to={routeItem.route}>
                                        <ListItemIcon>
                                            {routeItem.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={routeItem.title} />
                                    </ListItem>
                                ))}
                            </List>
                        </Menu>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {props.title}
                        </Typography>
                        <LoginButton isSmallDisplay={smallScreen} />
                    </Toolbar>
                </AppBar>
            </Box>
        )
    } else {
        return (
            <Box sx={{ display: 'flex' }}>
                <Drawer
                    PaperProps={paperProps}
                    sx={drawerSx}
                    variant="permanent"
                    anchor="left"
                >
                    <Toolbar sx={{ height: `${NAVBARHEIGHT}px` }}>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Station Registry
                        </Typography>
                    </Toolbar>
                    <Toolbar sx={{ justifyContent: "center", mb: 3 }}>
                        <LoginButton isSmallDisplay={smallScreen} />
                    </Toolbar>
                    <Toolbar>
                        <List>
                            {routeItems.map((routeItem, index) => (
                                <ListItem button key={index} component={RouterLink} to={routeItem.route}>
                                    <ListItemIcon>
                                        {routeItem.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={routeItem.title} />
                                </ListItem>
                            ))}
                        </List>
                    </Toolbar>
                </Drawer>
            </Box>
        );
    }
}

Sidebar.propTypes = {
    title: PropTypes.string.isRequired,
};


export default Sidebar;