import * as React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { Toolbar } from '@mui/material';
import StyledListSelection from '../StyledInputs/StyledListSelection';
import StyledTextInput from '../StyledInputs/StyledTextInput';
import StyledOutlinedButton from '../StyledButtons/StyledOutlinedButton';
import { filterSelectionSx } from '../../Utils/constants';
import { FORM_FIELD_ENTITYID, FORM_FIELD_STATIONTYPE } from '../../Utils/constants/formFieldCodes';
import { TITLE_PLURAL_STATION_TYPE } from '../../Utils/constants/titleCodes';
import { createSelectionList } from '../../Utils/helpers/listCreation';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const filterButton = { m: 1 };

const OnboardingFilter = (props) => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [entityIds, setEntityIds] = useState([]);
    const [stationTypes, setStationTypes] = useState([]);
    const [selectedStationType, setSelectedStationType] = useState([]);
    const [selectedEntityId, setSelectedEntityId] = useState([]);

    useEffect(() => {
        createSelectionList(props.rows, FORM_FIELD_ENTITYID, setEntityIds);
        createSelectionList(props.rows, FORM_FIELD_STATIONTYPE, setStationTypes);
        props.setDataView(props.rows);

    }, [props.rows]);

    const filterData = () => {
        const dataView = props.rows.filter((el) => {
            const hasEntityId = selectedEntityId.length === 0 ? true : selectedEntityId.includes(el.entityId);
            const hasStationType = selectedStationType.length === 0 ? true : selectedStationType.includes(el.stationType);

            return hasStationType && hasEntityId;
        });
        createSelectionList(dataView, FORM_FIELD_ENTITYID, setEntityIds);
        createSelectionList(dataView, FORM_FIELD_STATIONTYPE, setStationTypes);
        props.setDataView(dataView);
    }

    const refreshData = () => {
        props.setHasData(false);
    }

    const FilterSection = () => {
        if (smallScreen) {
            return (
                <Box>
                    <Toolbar>
                        <StyledTextInput
                            sx={{ m: 1, width: "80%" }}
                            label={"IDs"}
                            setSelection={setSelectedEntityId}
                            optionList={entityIds}
                        />
                    </Toolbar>
                    <Toolbar>
                        <StyledListSelection
                            sx={filterSelectionSx}
                            label={TITLE_PLURAL_STATION_TYPE}
                            setSelection={setSelectedStationType}
                            value={selectedStationType}
                            optionList={stationTypes}
                        />
                    </Toolbar>
                </Box>
            )
        } else {
            return (
                <Box>
                    <Toolbar>
                        <StyledTextInput
                            sx={{ m: 1, width: 400 }}
                            label={"IDs"}
                            setSelection={setSelectedEntityId}
                            optionList={entityIds}
                        />
                        <StyledListSelection
                            sx={filterSelectionSx}
                            label={TITLE_PLURAL_STATION_TYPE}
                            setSelection={setSelectedStationType}
                            value={selectedStationType}
                            optionList={stationTypes}
                        />
                    </Toolbar>
                </Box>
            )
        }
    }

    return (
        <Box>
            <FilterSection />
            <Toolbar>
                <StyledOutlinedButton
                    onClick={filterData}
                    sx={filterButton}
                >
                    Apply Filter
                </StyledOutlinedButton>
                <StyledOutlinedButton
                    onClick={refreshData}
                    sx={filterButton}
                >
                    Refresh Data
                </StyledOutlinedButton>
            </Toolbar>
        </Box>
    )
}


OnboardingFilter.propTypes = {
    rows: PropTypes.array.isRequired,
    setDataView: PropTypes.func.isRequired,
    setHasData: PropTypes.func.isRequired,
};




export default OnboardingFilter;