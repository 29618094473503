import { PRIMARY_LIGHT_COLORS, SECONDARY_LIGHT_COLORS } from "./colorSchemes";

export const navbarIconSx = {
    fontSize: 70,
    color: PRIMARY_LIGHT_COLORS.c600
}


export const alterBoxSx = {
    // m: 3,
    // p: 3,
    // borderRadius: 3,
    // backgroundColor: "#e7ebef",
}
