import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import EntityDisplay from './EntityDisplay';
import StyledOutlinedButton from '../../../StyledButtons/StyledOutlinedButton';
import ApiStation from '../../../../Utils/dataAccessClasses/ApiStation';
import ApiService from '../../../../Services/ApiService';
import ApiOrganization from '../../../../Utils/dataAccessClasses/ApiOrganization';
import ApiStationType from '../../../../Utils/dataAccessClasses/ApiStationType';
import ApiProject from '../../../../Utils/dataAccessClasses/ApiProject';
import ApiOnboardingService from '../../../../Utils/dataAccessClasses/ApiOnboardingService';
import { FORM_FIELD_STATIONTYPEID, FORM_FIELD_ORGANIZATIONID } from '../../../../Utils/constants/formFieldCodes';
import { 
    TITLE_SINGULAR_ONBOARDING_SERVICE,
    TITLE_SINGULAR_ORGANIZATION,
    TITLE_SINGULAR_PROJECT,
    TITLE_SINGULAR_STATION,
    TITLE_SINGULAR_STATION_TYPE 
} from '../../../../Utils/constants/titleCodes';
import { useStore } from '../../../../Services/StoreService';

const DeleteCompleteDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useStore()[1];

    const goBackAndRefresh = (response) => {
        let isSuccessful = !!response ? false : true;
        props.setOpenCompleteDialog(!isSuccessful);
        props.setOpenAlterDialog(!isSuccessful);
        dispatch('SUCCESSFUL_REQUEST', isSuccessful);
    };

    const handleSubmit = () => {
        const entity = props.entityForm.entity;
        switch (props.title) {
            case TITLE_SINGULAR_STATION:
                const station = new ApiStation();
                station.addEntityId(entity.entityId)
                
                ApiService.apiDeleteRequest(station.getResourceLink())
                    .then(res => {
                        goBackAndRefresh(res);
                    });
                break;
            case TITLE_SINGULAR_ORGANIZATION:
                const organization = new ApiOrganization();
                organization.addEntityId(entity.entityId);
                
                
                ApiService.apiDeleteRequest(organization.getResourceLink())
                    .then(res => {
                        goBackAndRefresh(res);
                    });
                break;
            case TITLE_SINGULAR_STATION_TYPE:
                const stationType = new ApiStationType();
                stationType.addEntityId(entity.entityId);
                stationType.addName(entity.name);
                stationType.addDescription(entity.description);
                
                ApiService.apiDeleteRequest(stationType.getResourceLink())
                    .then(res => {
                        goBackAndRefresh(res);
                    });
                break;
            case TITLE_SINGULAR_PROJECT:
                const project = new ApiProject();
                project.addEntityId(entity.entityId);
                project.addName(entity.name);
                project.addStartDate(entity.startDate);
                project.addEndDate(entity.endDate);
                
                ApiService.apiDeleteRequest(project.getResourceLink())
                    .then(res => {
                        goBackAndRefresh(res);
                    });
                break;
            case TITLE_SINGULAR_ONBOARDING_SERVICE:
                const onboarding = new ApiOnboardingService();
                onboarding.addEntityId(entity.entityId);
                ApiService.apiDeleteRequest(onboarding.getResourceLink())
                    .then(res => {
                        goBackAndRefresh(res);
                    });
                break;
            default:
                break;
        }
        
    };

    const handleGoBack = () => {
        props.setOpenCompleteDialog(false);
    };

    const formatEntity = (el) => {
        try {
            let formattedEntry = {};
            Object.entries(el).map(([key, value]) => {
                try {
                    // Do not display these fields
                    const isIdField = [FORM_FIELD_ORGANIZATIONID, FORM_FIELD_STATIONTYPEID].includes(key);
                    if (!isIdField) {
                        value = ["startDate", "endDate"].includes(key) ? value.toDateString() : value;
                        formattedEntry[props.entityForm.format[key]] = value;
                    }
                } catch (error) {

                }
            })
            return formattedEntry;
        } catch (e) { }
    }


    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            fullScreen={fullScreen}
            open={props.openCompleteDialog}
        >
            <DialogTitle>
                Do you really want to delete this {props.title.toLowerCase()}?
            </DialogTitle>
            <DialogContent>
                <EntityDisplay
                    entity={formatEntity(props.entityForm.entity)}
                    title={props.title}
                />
            </DialogContent>
            <DialogActions>
                <StyledOutlinedButton onClick={handleSubmit} autoFocus>
                    Delete {props.title}
                </StyledOutlinedButton>
                <StyledOutlinedButton autoFocus onClick={handleGoBack}>
                    Go Back
                </StyledOutlinedButton>
            </DialogActions>
        </Dialog>
    )
}

DeleteCompleteDialog.propTypes = {
    entityForm: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    openCompleteDialog: PropTypes.bool.isRequired,
    setOpenCompleteDialog: PropTypes.func.isRequired,
    setOpenAlterDialog: PropTypes.func.isRequired,
};

export default DeleteCompleteDialog;