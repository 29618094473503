import * as React from 'react';
import StationFilter from "../Components/FilterSections/StationFilter";
import StationAlter from '../Components/AlterSections/StationAlter';
import ApiTable from "../Components/Table/ApiTable";
import { useState, useEffect } from 'react';
import ApiService from "../Services/ApiService";
import { STATIONHEAD } from "../Utils/constants/tableHeaders";
import StationIcon from '../Components/PageIcons/StationIcon';
import { navbarIconSx } from '../Utils/styles/sxStyles';
import { transformStationData, transformOrganizationData, transformStationTypeData } from '../Utils/helpers/apiTransformation';
import { ENDPOINT_ORGANIZATIONS, ENDPOINT_STATIONS, ENDPOINT_STATION_TYPES } from '../Utils/constants/apiEndpointCodes';
import { TITLE_PLURAL_STATION } from '../Utils/constants/titleCodes';
import {
    FORM_FIELD_ENTITYID,
    FORM_FIELD_NAME,
    FORM_FIELD_ORGANIZATION,
    FORM_FIELD_ACCESSLEVEL,
    FORM_FIELD_STATIONTYPE,
    FORM_FIELD_ONBOARDING
} from "../Utils/constants/formFieldCodes";
import { useStore } from '../Services/StoreService';
import PageWrapper from '../Components/PageWrapper';

const StationsPage = () => {

    const [stationData, setStationData] = useState([]);
    const [stationTypeData, setStationTypeData] = useState([]);
    const [organizationData, setOrganizationData] = useState([]);
    const [dataView, setDataView] = useState([]);
    const [hasData, setHasData] = useState(false);
    const [globalState, dispatch] = useStore();

    useEffect(() => {
        if (globalState.isSuccess || !hasData) {
            ApiService.apiGetRequest(ENDPOINT_STATIONS, (res) => transformStationData(res, setStationData));
            ApiService.apiGetRequest(ENDPOINT_ORGANIZATIONS, (res) => transformOrganizationData(res, setOrganizationData));
            ApiService.apiGetRequest(ENDPOINT_STATION_TYPES, (res) => transformStationTypeData(res, setStationTypeData));
            setDataView(stationData);
            setHasData(true);
            dispatch('SUCCESSFUL_REQUEST', false);
        }
    }, [hasData, stationData, globalState]);

    const formatDataView = (dataView) => {
        const formattedDataView = dataView.map((el) => {
            return {
                [FORM_FIELD_ENTITYID]: el.entityId,
                [FORM_FIELD_NAME]: el.name,
                [FORM_FIELD_ORGANIZATION]: el.organization,
                [FORM_FIELD_ACCESSLEVEL]: el.accessLevel,
                [FORM_FIELD_STATIONTYPE]: el.stationType,
                [FORM_FIELD_ONBOARDING]: el.onboarding
            }
        });
        return formattedDataView;
    }

    return (
        <PageWrapper
            title={TITLE_PLURAL_STATION}
            icon={<StationIcon sx={navbarIconSx}/>}
        >
            <StationAlter
                stationData={stationData}
                stationTypeData={stationTypeData}
                organizationData={organizationData}
                setHasData={setHasData}
            />
            <StationFilter
                rows={stationData}
                setDataView={setDataView}
                setHasData={setHasData}
            />
            <ApiTable
                headCells={STATIONHEAD}
                rows={formatDataView(dataView)}
                setRows={setStationData}
            />
        </PageWrapper>
    )
}

export default StationsPage;
