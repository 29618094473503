import * as React from 'react';
import { useState, useEffect } from 'react';
import OnboardingFilter from '../Components/FilterSections/OnboardingFilter';
import ApiService from '../Services/ApiService';
import ApiTable from '../Components/Table/ApiTable';
import OnboardingIcon from '../Components/PageIcons/OnboardingIcon';
import OnboardingAlter from '../Components/AlterSections/OnboardingAlter';
import { ONBOARDINGHEAD } from '../Utils/constants/tableHeaders';
import { navbarIconSx } from '../Utils/styles/sxStyles';
import { transformOnboardingServiceData, transformStationTypeData } from '../Utils/helpers/apiTransformation';
import { ENDPOINT_ONBOARDING_SERVICES, ENDPOINT_STATION_TYPES } from '../Utils/constants/apiEndpointCodes';
import { TITLE_PLURAL_ONBOARDING_SERVICE } from '../Utils/constants/titleCodes';
import { FORM_FIELD_ENTITYID, FORM_FIELD_ONBOARDINGSERVICE, FORM_FIELD_STATIONTYPE } from '../Utils/constants/formFieldCodes';
import { useStore } from '../Services/StoreService';
import PageWrapper from '../Components/PageWrapper';

const OnboardingServicePage = () => {

    const [rows, setRows] = useState([]);
    const [stationTypeRows, setStationTypeRows] = useState([]);
    const [dataView, setDataView] = useState([]);
    const [hasData, setHasData] = useState(false);
    const [globalState, dispatch] = useStore();

    useEffect(() => {
        if (globalState.isSuccess || !hasData) {
            ApiService.apiGetRequest(ENDPOINT_ONBOARDING_SERVICES, (res) => transformOnboardingServiceData(res, setRows));
            ApiService.apiGetRequest(ENDPOINT_STATION_TYPES, (res) => transformStationTypeData(res, setStationTypeRows));
            setDataView(rows);
            setHasData(true);
            dispatch('SUCCESSFUL_REQUEST', false);
        }
    }, [hasData, rows, globalState]);

    const formatDataView = (dataView) => {
        const formattedDataView = dataView.map((el) => {
            return {
                [FORM_FIELD_ENTITYID]: el.entityId,
                [FORM_FIELD_STATIONTYPE]: el.stationType,
                [FORM_FIELD_ONBOARDINGSERVICE]: el.onboardingService,
            }
        });
        return formattedDataView;
    }

    return (
        <PageWrapper
            title={TITLE_PLURAL_ONBOARDING_SERVICE}
            icon={<OnboardingIcon sx={navbarIconSx} />}
        >
            <OnboardingAlter
                rows={rows}
                stationTypeRows={stationTypeRows}
            />
            <OnboardingFilter
                rows={rows}
                setDataView={setDataView}
                setHasData={setHasData}
            />
            <ApiTable
                headCells={ONBOARDINGHEAD}
                rows={formatDataView(dataView)}
                setRows={setRows}
            />
        </PageWrapper>
    )
}

export default OnboardingServicePage;