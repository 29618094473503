import * as React from 'react';
import {
    FormControl,
    Box,
} from "@mui/material";
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AddCompleteDialog from './CompleteDialogs/AddCompleteDialog';
import StyledContainedButton from '../../StyledButtons/StyledContainedButton';
import StyledOutlinedButton from '../../StyledButtons/StyledOutlinedButton';
import UserService from '../../../Services/UserService';
import {
    FORM_FIELD_ENTITYID,
    FORM_FIELD_ORGANIZATIONID,
    FORM_FIELD_STATIONTYPEID,
    FORM_FIELD_STARTDATE,
    FORM_FIELD_ENDDATE
} from '../../../Utils/constants/formFieldCodes';
import { UNIT_OF_DAY } from '../../../Utils/constants';


const AlterSectionsAdd = (props) => {
    const [open, setOpen] = useState(false);
    const [entityForm, setEntityForm] = useState(props.defaultForm());
    const [isSuccessful, setIsSuccessful] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setEntityForm(props.defaultForm());
        setOpen(false);
    };

    /**
     * Returns true if all entries of an object are false.
     * @param {*} errorList 
     * @returns 
     */
    const isValid = (errorList) => {
        return !Object.values(errorList).find(el => el);
    }

    const handleSubmit = () => {
        let items = { ...entityForm };
        Object.entries(items.entity).map(([key, value]) => {
            // Check if values are empty
            const isDate = [FORM_FIELD_STARTDATE, FORM_FIELD_ENDDATE].includes(key);
            const isIdField = [FORM_FIELD_ENTITYID, FORM_FIELD_ORGANIZATIONID, FORM_FIELD_STATIONTYPEID].includes(key);
            if (
                !isDate
                && !isIdField
                && value.toString().trim() === ""
                && key !== "onboarding"
            ) {
                items.errors[key] = true;
            } else {
                items.errors[key] = false;
            }
            // Check if time difference is positive
            if (key === FORM_FIELD_STARTDATE) {
                const diffDays = Math.round((items.entity[FORM_FIELD_ENDDATE] - value) / UNIT_OF_DAY);
                if (diffDays < 1) {
                    items.errors[key] = true;
                }
            }
        });
        const isValidEntry = isValid(items.errors);
        setIsSuccessful(isValidEntry);
        setEntityForm(items);
    };

    const getNameById = (id, idList) => {
        const element = idList.find((el) => {
            return el.id === id;
        })
        return !!element ? element.name : "";
    }

    const handleFormChange = (event, formLocation, idList) => {
        let items = { ...entityForm };
        switch (event.constructor) {
            case String:
                items.entity[formLocation] = event;
                break;
            case Array:
                items.entity[formLocation] = event;
                break;
            case Date:
                items.entity[formLocation] = event;
                break;
            case Object:
                items.entity[formLocation] = event.name;
                items.entity[formLocation + "Id"] = event.id;
                break;
            case window.PointerEvent:
                items.entity[formLocation] = getNameById(event.target.value, idList);
                items.entity[formLocation + "Id"] = event.target.value;
                break;
            default:
                items.entity[formLocation] = getNameById(event.target.value, idList);
                items.entity[formLocation + "Id"] = event.target.value;
                break;
        }
        setEntityForm(items);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    return (
        <Box sx={{ m: 1 }}>
            <StyledContainedButton
                disabled={UserService.isLoggedIn() ? false : true}
                onClick={handleClickOpen}
            >
                Add {props.entityName}
            </StyledContainedButton>
            <Dialog
                fullWidth={true}
                maxWidth={"xs"}
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    {"Specify the new " + props.entityName}
                </DialogTitle>
                <DialogContent
                    sx={{ alignContent: "center" }}
                >
                    <FormControl>
                        {props.alterInputs(entityForm, handleFormChange)}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <StyledOutlinedButton autoFocus onClick={handleSubmit}>
                        Create {props.entityName}
                    </StyledOutlinedButton>
                    <StyledOutlinedButton autoFocus onClick={handleClose}>
                        Cancel
                    </StyledOutlinedButton>
                </DialogActions>
            </Dialog>
            <AddCompleteDialog
                title={props.entityName}
                entityForm={entityForm}
                openCompleteDialog={isSuccessful}
                setOpenCompleteDialog={setIsSuccessful}
                setOpenAlterDialog={setOpen}
            />
        </Box>
    )
}

AlterSectionsAdd.propTypes = {
    defaultForm: PropTypes.func.isRequired,
    entityName: PropTypes.string.isRequired,
    alterInputs: PropTypes.any.isRequired,
};

AlterSectionsAdd.defaultProps = {
}


export default AlterSectionsAdd;