/**
 * Create a list of objects for a selection input.4
 * @param {*} data 
 * @param {*} attribute 
 * @param {*} setStateFunc 
 */
export const createKeyValueList = (data, attribute, idField, setStateFunc) => {
    let uniques = {};
    let elementList = data.map((el) => {
        if (!uniques[el[idField]]) {
            uniques[el[idField]] = "None";
            return { name: el[attribute], id: el[idField] };
        }
    });
    elementList.sort((a, b) => a.name.localeCompare(b.name));
    elementList.filter((el) => el !== undefined);
    setStateFunc([...new Set(elementList.filter((el) => el !== undefined))]);
}


/**
 * Create a list of strings.
 * 
 * WARNING: This function assumes the attributes of a list are unique
 * @param {*} data 
 * @param {*} attribute 
 * @param {*} setStateFunc 
 */
export const createSelectionList = (data, attribute, setStateFunc) => {
    let elementList = data.map((el) => {
        return el[attribute];
    });
    elementList.sort((a, b) => a.localeCompare(b));
    setStateFunc([...new Set(elementList)]);
}