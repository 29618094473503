import { ENDPOINT_STATION_TYPES } from "../constants/apiEndpointCodes";
import { 
    API_ATTRIBUTE_ENTITY_ID,
    API_ATTRIBUTE_NAME,
    API_ATTRIBUTE_DESCRIPTION
} from "../constants/apiAttributeCodes";

/**
 * This is a class
 */
class ApiStationType {

    dataModel = {
        [API_ATTRIBUTE_ENTITY_ID]: null,
        [API_ATTRIBUTE_NAME]: null,
        [API_ATTRIBUTE_DESCRIPTION]: null
    };

    resourceLink = null;
    postLink = ENDPOINT_STATION_TYPES;

    addEntityId = (entityId) => {
        this.entityId = entityId;
        this.addResourceLink(entityId);
    }

    addName = (name) => {
        this.name = name;
    }

    addDescription = (description) => {
        this.description = description;
    }  
    
    /**
     * Link needed for PUT and DELETE requests
     * @param {*} entityId 
     */
    addResourceLink = (entityId) => {
        this.resourceLink = this.postLink + entityId;
    }

    getPostLink = () => {
        return this.postLink;
    }

    getResourceLink = () => {
        return this.resourceLink;
    }

    getData = () => {
        this.dataModel[API_ATTRIBUTE_ENTITY_ID] = this.entityId;
        this.dataModel[API_ATTRIBUTE_NAME] = this.name;
        this.dataModel[API_ATTRIBUTE_DESCRIPTION] = this.description;
        return this.dataModel;
    }

}

export default ApiStationType;