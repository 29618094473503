import * as React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { Toolbar } from '@mui/material';
import StyledListSelection from '../StyledInputs/StyledListSelection';
import StyledTextInput from '../StyledInputs/StyledTextInput';
import StyledOutlinedButton from '../StyledButtons/StyledOutlinedButton';
import { filterSelectionSx } from '../../Utils/constants';
import { FORM_FIELD_ENTITYID, FORM_FIELD_NAME, FORM_FIELD_ORGANIZATION } from '../../Utils/constants/formFieldCodes';
import { TITLE_PLURAL_ORGANIZATION } from '../../Utils/constants/titleCodes';
import { createSelectionList } from '../../Utils/helpers/listCreation';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const filterButton = { m: 1 };

const StationFilter = (props) => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { rows, setDataView, setHasData } = props;

    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [selectedStationName, setSelectedStationName] = useState([]);
    const [stationNames, setStationNames] = useState([]);
    const [entityIds, setEntityIds] = useState([]);
    const [selectedEntityId, setSelectedEntityId] = useState([]);
    const [selectedAccessLevel, setSelectedAccessLevel] = useState([]);
    const [selectedOnboarding, setSelectedOnboarding] = useState([]);

    useEffect(() => {
        createSelectionList(rows, FORM_FIELD_ORGANIZATION, setOrganizations);
        createSelectionList(rows, FORM_FIELD_NAME, setStationNames);
        createSelectionList(rows, FORM_FIELD_ENTITYID, setEntityIds);

        setDataView(rows);

    }, [rows]);

    const filterData = () => {
        const dataView = rows.filter((el) => {
            const hasOrganization = selectedOrganization.length === 0 ? true : selectedOrganization.includes(el.organization);
            const hasAccessLevel = selectedAccessLevel.length === 0 ? true : selectedAccessLevel.includes(el.accessLevel);
            const hasOnboarding = selectedOnboarding.length === 0 ? true : selectedOnboarding.includes(el.onboarding);
            const hasEntityId = selectedEntityId.length === 0 ? true : selectedEntityId.includes(el.entityId);
            const hasStationName = selectedStationName.length === 0 ? true : selectedStationName.includes(el.name);

            return hasOrganization &&
                hasAccessLevel &&
                hasOnboarding &&
                hasEntityId &&
                hasStationName;
        });
        createSelectionList(dataView, FORM_FIELD_ORGANIZATION, setOrganizations);
        createSelectionList(dataView, FORM_FIELD_NAME, setStationNames);
        createSelectionList(dataView, FORM_FIELD_ENTITYID, setEntityIds);
        setDataView(dataView);
    }

    const refreshData = () => {
        setHasData(false);
    }

    const FilterSection = () => {
        if (smallScreen) {
            return (
                <Box>
                    <Toolbar>
                        <StyledTextInput
                            sx={{ m: 1, width: 400 }}
                            label={"IDs"}
                            setSelection={setSelectedEntityId}
                            optionList={entityIds}
                        />
                    </Toolbar>
                    <Toolbar>
                        <StyledListSelection
                            sx={filterSelectionSx}
                            label={"Station Names"}
                            setSelection={setSelectedStationName}
                            value={selectedStationName}
                            optionList={stationNames}
                        />
                        <StyledListSelection
                            sx={filterSelectionSx}
                            label={TITLE_PLURAL_ORGANIZATION}
                            setSelection={setSelectedOrganization}
                            value={selectedOrganization}
                            optionList={organizations}
                        />
                    </Toolbar>
                    <Toolbar>
                        <StyledListSelection
                            sx={filterSelectionSx}
                            label={"Access Level"}
                            setSelection={setSelectedAccessLevel}
                            value={selectedAccessLevel}
                            optionList={["PUBLIC", "PRIVATE"]}
                        />
                        <StyledListSelection
                            sx={filterSelectionSx}
                            label={"Onboarding Status"}
                            setSelection={setSelectedOnboarding}
                            value={selectedOnboarding}
                            optionList={["ONLINE", "OFFLINE"]}
                        />
                    </Toolbar>
                </Box>
            )
        } else {
            return (
                <Box>
                    <Toolbar>
                        <StyledTextInput
                            sx={{ m: 1, width: 400 }}
                            label={"IDs"}
                            setSelection={setSelectedEntityId}
                            optionList={entityIds}
                        />
                        <StyledListSelection
                            sx={filterSelectionSx}
                            label={"Station Names"}
                            setSelection={setSelectedStationName}
                            value={selectedStationName}
                            optionList={stationNames}
                        />
                    </Toolbar>
                    <Toolbar>
                        <StyledListSelection
                            sx={filterSelectionSx}
                            label={TITLE_PLURAL_ORGANIZATION}
                            setSelection={setSelectedOrganization}
                            value={selectedOrganization}
                            optionList={organizations}
                        />
                        <StyledListSelection
                            sx={filterSelectionSx}
                            label={"Access Level"}
                            setSelection={setSelectedAccessLevel}
                            value={selectedAccessLevel}
                            optionList={["PUBLIC", "PRIVATE"]}
                        />
                        <StyledListSelection
                            sx={filterSelectionSx}
                            label={"Onboarding Status"}
                            setSelection={setSelectedOnboarding}
                            value={selectedOnboarding}
                            optionList={["ONLINE", "OFFLINE"]}
                        />
                    </Toolbar>
                </Box>
            )
        }
    }

    return (
        <Box>
            <FilterSection />
            <Toolbar>
                <StyledOutlinedButton
                    sx={filterButton}
                    onClick={filterData}
                >
                    Apply Filter
                </StyledOutlinedButton>
                <StyledOutlinedButton
                    sx={filterButton}
                    onClick={refreshData}
                >
                    Refresh Data
                </StyledOutlinedButton>
            </Toolbar>
        </Box>
    )
}


StationFilter.propTypes = {
    rows: PropTypes.array.isRequired,
    setDataView: PropTypes.func.isRequired,
    setHasData: PropTypes.func.isRequired,
};




export default StationFilter;
