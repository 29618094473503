import { AppBar, Box } from "@mui/material";
import { Toolbar } from "@mui/material";
import { Typography } from "@mui/material";
import { DRAWERWIDTH, NAVBARHEIGHT } from "../Utils/constants";
import { PropTypes } from "prop-types";
import { PRIMARY_LIGHT_COLORS } from "../Utils/styles/colorSchemes";

const titleSx = { 
    m: 2, 
    color: PRIMARY_LIGHT_COLORS.c600
}

const Navbar = (props) => {
    return (
        <AppBar
            elevation={0}
            position="fixed"
            sx={{
                height: `${NAVBARHEIGHT}px`,
                width: `calc(100% - ${DRAWERWIDTH}px)`,
                // ml: `${DRAWERWIDTH}px`,
                justifyContent: "center",
                // backgroundColor: PRIMARY_LIGHT_COLORS.c400,
                backgroundColor: "#FFFFFF",
                color: "black",
                // borderBottom: "1px solid black"
            }}
        >
            <Toolbar>
                {props.icon}
                <Typography 
                    variant="h2"
                    noWrap
                    component="div"
                    sx={titleSx}>
                    {props.title}
                </Typography>
                <Toolbar/>
            </Toolbar>
        </AppBar>
    )
}


Navbar.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
};



export default Navbar;