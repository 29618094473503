import { Button } from "@mui/material";
import PropTypes from 'prop-types';
import { PRIMARY_LIGHT_COLORS } from "../../Utils/styles/colorSchemes";


const buttonContainedSx = {
    color: PRIMARY_LIGHT_COLORS.c50,
    backgroundColor: PRIMARY_LIGHT_COLORS.c600,
    '&:hover': {
        backgroundColor: PRIMARY_LIGHT_COLORS.c700,
    },
    "& .MuiButton-Contained.Mui-disabled": {
        backgroundColor: "black",
    },
}

const StyledContainedButton = (props) => {
    const { ...other } = props;

    return (
        <Button
            {...other}
            variant='contained'
            sx={{ ...buttonContainedSx, ...props.sx }}
        >
            {props.children}
        </Button>
    )
}

StyledContainedButton.propTypes = {
    children: PropTypes.any.isRequired,
    sx: PropTypes.object,
};

export default StyledContainedButton;
