import * as React from 'react';
import {
    Toolbar,
    Box,
    TextField,
    Stack,
} from "@mui/material";
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AlterSectionsEdit from '../../Components/AlterSections/AlterDialogs/AlterSectionsEdit';
import AlterSectionsAdd from '../../Components/AlterSections/AlterDialogs/AlterSectionsAdd';
import FilterInput from '../StyledInputs/StyledTextInput';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { alterBoxSx } from '../../Utils/styles/sxStyles';
import { filterSelectionSx } from '../../Utils/constants';
import { FORM_FIELD_ENDDATE, FORM_FIELD_ENTITYID, FORM_FIELD_NAME, FORM_FIELD_STARTDATE } from "../../Utils/constants/formFieldCodes";
import { TITLE_SINGULAR_PROJECT } from '../../Utils/constants/titleCodes';
import { createSelectionList } from '../../Utils/helpers/listCreation';


const getDefaultForm = () => {
    return {
        format: {
            entityId: "Id",
            name: "Name",
            startDate: "Start Date",
            endDate: "End Date",
        },
        entity: {
            entityId: "None",
            name: "",
            startDate: new Date(),
            endDate: new Date(),
        },
        errors: {
            entityId: false,
            name: false,
            startDate: false,
            endDate: false,
        }
    }
}

const ProjectsAlter = (props) => {

    const [entityIds, setEntityIds] = useState([]);

    useEffect(() => {
        createSelectionList(props.rows, FORM_FIELD_ENTITYID, setEntityIds);
    }, [props.rows]);

    const addInputs = (entityForm, handleFormChange) => {
        return (
            <Box>
                <TextField
                    sx={filterSelectionSx}
                    error={entityForm.errors.name}
                    helperText={entityForm.errors.name ? "Please fill in a name" : null}
                    label={"Name"}
                    value={entityForm.entity.name}
                    onChange={(event) => handleFormChange(event.target.value, FORM_FIELD_NAME)}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{ m: 1 }}
                    >
                        <DatePicker
                            label="Start Date"
                            openTo="day"
                            views={['day']}
                            maxDate={entityForm.entity.endDate}
                            value={entityForm.entity.startDate}
                            onChange={(event) => handleFormChange(event, FORM_FIELD_STARTDATE)}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        error={entityForm.errors.startDate}
                                        helperText={entityForm.errors.startDate ? "Time difference can not be < 1" : null}
                                        {...params}
                                    />
                                );
                            }
                            }
                        />
                        <DatePicker
                            label="End Date"
                            openTo="day"
                            views={['day']}
                            minDate={entityForm.entity.minDate}
                            value={entityForm.entity.endDate}
                            onChange={(event) => handleFormChange(event, FORM_FIELD_ENDDATE)}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        error={entityForm.errors.startDate}
                                        helperText={entityForm.errors.startDate ? "Time difference can not be < 1" : null}
                                        {...params}
                                    />
                                );
                            }
                            }
                        />
                    </Stack>
                </LocalizationProvider>
            </Box>
        )
    }

    const editInputs = (entityForm, selectEntity, isDisabled, handleFormChange) => {
        return (
            <Box>
                <FilterInput
                    sx={{ m: 1, width: "80%" }}
                    label={"IDs"}
                    setSelection={selectEntity}
                    optionList={entityIds}
                    multiSelect={false}
                    isError={entityForm.errors.entityId}
                />
                <TextField
                    sx={filterSelectionSx}
                    error={entityForm.errors.name}
                    helperText={entityForm.errors.name ? "Please fill in a name" : null}
                    label={"Name"}
                    value={entityForm.entity.name}
                    onChange={(event) => handleFormChange(event.target.value, FORM_FIELD_NAME)}
                    disabled={isDisabled}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{ m: 1 }}
                    >
                        <DatePicker
                            label="Start Date"
                            openTo="day"
                            views={['day']}
                            value={entityForm.entity.startDate}
                            onChange={(event) => handleFormChange(event, FORM_FIELD_STARTDATE)}
                            disabled={isDisabled}
                            error={true}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        error={entityForm.errors.startDate}
                                        helperText={entityForm.errors.startDate ? "Time difference can not be < 1" : null}
                                        {...params}
                                    />
                                );
                            }
                            }
                        />
                        <DatePicker
                            label="End Date"
                            openTo="day"
                            views={['day']}
                            value={entityForm.entity.endDate}
                            onChange={(event) => handleFormChange(event, FORM_FIELD_ENDDATE)}
                            disabled={isDisabled}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        error={entityForm.errors.startDate}
                                        helperText={entityForm.errors.startDate ? "Time difference can not be < 1" : null}
                                        {...params}
                                    />
                                );
                            }
                            }
                        />
                    </Stack>
                </LocalizationProvider>
            </Box>
        )
    }

    return (
        <Box sx={alterBoxSx}>
            <Toolbar>
                <AlterSectionsAdd
                    entityName={TITLE_SINGULAR_PROJECT}
                    alterInputs={addInputs}
                    defaultForm={getDefaultForm}
                />
                <AlterSectionsEdit
                    rows={props.rows}
                    entityName={TITLE_SINGULAR_PROJECT}
                    alterInputs={editInputs}
                    defaultForm={getDefaultForm}
                />
            </Toolbar>
        </Box>
    )
}

ProjectsAlter.propTypes = {
    rows: PropTypes.array.isRequired,
};



export default ProjectsAlter;