import { ENDPOINT_ORGANIZATIONS, ENDPOINT_STATIONS } from "../constants/apiEndpointCodes";
import { 
    API_ATTRIBUTE_ENTITY_ID,
    API_ATTRIBUTE_STATION_TYPE,
    API_ATTRIBUTE_ORGANIZATION,
    API_ATTRIBUTE_LINK_LIST,
    API_ATTRIBUTE_ACCESS_LEVEL,
    API_ATTRIBUTE_ONBOARDING,
    API_ATTRIBUTE_NAME
} from "../constants/apiAttributeCodes";

/**
 * This is a class
 */
class ApiStation {

    dataModel = {
        [API_ATTRIBUTE_ENTITY_ID]: null,
        [API_ATTRIBUTE_NAME]: null,
        [API_ATTRIBUTE_ACCESS_LEVEL]: null,
        [API_ATTRIBUTE_ONBOARDING]: null,
        [API_ATTRIBUTE_LINK_LIST]: [
            { name: API_ATTRIBUTE_ORGANIZATION, uri: null },
            { name: API_ATTRIBUTE_STATION_TYPE, uri: null },
        ]
    };
    entityId = null;
    name = null;
    accessLevel = null;
    onboarding = null;
    stationTypeId = null;
    organizationId = null;

    /**Link used for POST requests */
    postLink = null;
    /**Link to single entity */
    resourceLink = null;

    addEntityId = (entityId) => {
        this.entityId = entityId;
        this.addResourceLink(entityId);
    }

    addOrganization = (organizationId) => {
        this.organizationId = organizationId;
        this.addPostLink(organizationId);
    }

    addStationType = (stationTypeId) => {
        this.stationTypeId = stationTypeId;
    }

    addName = (name) => {
        this.name = name;
    }

    addAccessLevel = (accessLevel) => {
        this.accessLevel = accessLevel;
    }

    addOnboarding = (onboarding) => {
        this.onboarding = onboarding;
    }

    addPostLink = (organizationId) => {
        this.postLink = ENDPOINT_ORGANIZATIONS + organizationId + "/" + ENDPOINT_STATIONS;
    }

    /**
     * Link needed for PUT and DELETE requests
     * @param {*} entityId 
     */
    addResourceLink = (entityId) => {
        this.resourceLink = ENDPOINT_STATIONS + entityId;
    }

    getPostLink = () => {
        return this.postLink;
    }

    getResourceLink = () => {
        return this.resourceLink;
    }

    getData = () => {
        this.dataModel[API_ATTRIBUTE_ENTITY_ID] = this.entityId;
        this.dataModel[API_ATTRIBUTE_NAME] = this.name;
        this.dataModel[API_ATTRIBUTE_ACCESS_LEVEL] = this.accessLevel;
        this.dataModel[API_ATTRIBUTE_ONBOARDING] = this.onboarding;
        this.dataModel[API_ATTRIBUTE_LINK_LIST].map((el) => {
            if (el.name === API_ATTRIBUTE_STATION_TYPE) {
                el.uri = this.stationTypeId;
            } else if (el.name === API_ATTRIBUTE_ORGANIZATION) {
                el.uri = this.organizationId;
            }
        });

        return this.dataModel;
    }

}

export default ApiStation;