import { Button } from "@mui/material";
import PropTypes from 'prop-types';
import { PRIMARY_LIGHT_COLORS } from "../../Utils/styles/colorSchemes";


const buttonOutlinedSx = {
    backgroundColor: PRIMARY_LIGHT_COLORS.c50,
    color: PRIMARY_LIGHT_COLORS.c500,
    borderColor: PRIMARY_LIGHT_COLORS.c500,
    '&:hover': {
        backgroundColor: PRIMARY_LIGHT_COLORS.c100,
        borderColor: PRIMARY_LIGHT_COLORS.c500
    }
}

const StyledOutlinedButton = (props) => {
    const { ...other } = props;

    return (
        <Button
            {...other}
            variant='outlined'
            sx={{ ...buttonOutlinedSx, ...props.sx }}
        >
            {props.children}
        </Button>
    )
}

StyledOutlinedButton.propTypes = {
    children: PropTypes.any.isRequired,
    sx: PropTypes.object,
};

export default StyledOutlinedButton;
