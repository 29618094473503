import * as React from "react";
import RenderOnAnonymous from './RenderOnAnonymous';
import RenderOnAuthenticated from './RenderOnAuthenticated';
import UserService from '../Services/UserService';
import { Box } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StyledOutlinedButton from './StyledButtons/StyledOutlinedButton';
import PropTypes from 'prop-types';

const iconSize = "70px";
const iconStyle = {
    height: iconSize,
    width: iconSize,
    mb: 3,
    // backgroundColor: "green"
}

export const loginBoxSx = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 5
}

const LoginButton = (props) => {

    if (props.isSmallDisplay) {
        return (
            <Box sx={loginBoxSx}>
                <RenderOnAnonymous>
                    <StyledOutlinedButton
                        onClick={() => UserService.doLogin()}
                    >
                        Login
                    </StyledOutlinedButton>
                </RenderOnAnonymous>
                <RenderOnAuthenticated>
                    <StyledOutlinedButton
                        onClick={() => UserService.doLogout()}
                    >
                        Logout
                    </StyledOutlinedButton>
                </RenderOnAuthenticated>            </Box>
        )

    } else {
        return (
            <Box sx={loginBoxSx}>
                <RenderOnAnonymous>
                    <AccountCircleIcon sx={iconStyle} />
                    <StyledOutlinedButton
                        onClick={() => UserService.doLogin()}
                    >
                        Login
                    </StyledOutlinedButton>
                </RenderOnAnonymous>
                <RenderOnAuthenticated>
                    <AccountCircleIcon sx={iconStyle} />
                    <StyledOutlinedButton
                        onClick={() => UserService.doLogout()}
                    >
                        Logout
                    </StyledOutlinedButton>
                </RenderOnAuthenticated>
            </Box>
        )
    }
}


LoginButton.propTypes = {
    isSmallDisplay: PropTypes.bool.isRequired,
};



export default LoginButton;
