/**Field name which gets returned by the api */
export const API_ATTRIBUTE_ENTITY_ID = "id";
/**Field name which gets returned by the api */
export const API_ATTRIBUTE_NAME = "name";
/**Field name which gets returned by the api */
export const API_ATTRIBUTE_STATION_TYPE = "station-type";
/**Field name which gets returned by the api */
export const API_ATTRIBUTE_ACCESS_LEVEL = "access-level";
/**Field name which gets returned by the api */
export const API_ATTRIBUTE_ORGANIZATION = "organization";
/**Field name which gets returned by the api */
export const API_ATTRIBUTE_ONBOARDING = "onboarding-status";
/**Field name which gets returned by the api */
export const API_ATTRIBUTE_LINK_LIST = "link-list";
/**Field name which gets returned by the api */
export const API_ATTRIBUTE_DESCRIPTION = "description";
/**Field name which gets returned by the api */
export const API_ATTRIBUTE_START_DATE = "start-date";
/**Field name which gets returned by the api */
export const API_ATTRIBUTE_END_DATE = "end-date";
/**Field name which gets returned by the api */
export const API_ATTRIBUTE_URI = "uri";
/**Field name which gets returned by the api */
export const API_ATTRIBUTE_STATION = "station";
/**Field name which gets returned by the api */
export const API_ATTRIBUTE_EMAIL = "e-mail";