import * as React from "react";
import { Box } from "@mui/system";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import StationsPage from './Pages/StationsPage';
import ProjectsPage from './Pages/ProjectsPage';
import OrganizationsPage from './Pages/OrganizationsPage';
import StationTypesPage from './Pages/StationTypesPage';
import OnboardingServicePage from './Pages/OnboardingServicePage';
import {
    ROUTES_BLANK,
    ROUTES_STATION,
    ROUTES_PROJECTS,
    ROUTES_ORGANIZATION,
    ROUTES_STATIONTYPES,
    ROUTES_ONBOARDINGSERVICES
} from "./Utils/constants/routeCodes";

export default function App() {

    return (
        <Box className="App">
            <BrowserRouter>
                <Routes>
                    <Route path={ROUTES_BLANK} element={<StationsPage />} />
                    <Route path={ROUTES_STATION} element={<StationsPage />} />
                    <Route path={ROUTES_PROJECTS} element={<ProjectsPage />} />
                    <Route path={ROUTES_ORGANIZATION} element={<OrganizationsPage />} />
                    <Route path={ROUTES_STATIONTYPES} element={<StationTypesPage />} />
                    <Route path={ROUTES_ONBOARDINGSERVICES} element={<OnboardingServicePage />} />
                </Routes>
            </BrowserRouter>
        </Box>
    );
}
