import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import EntityDisplay from './EntityDisplay';
import StyledOutlinedButton from '../../../StyledButtons/StyledOutlinedButton';
import ApiService from '../../../../Services/ApiService';
import ApiStationOnboarding from '../../../../Utils/dataAccessClasses/ApiStationOnboarding';
import { FORM_FIELD_ENTITYID, FORM_FIELD_STATIONTYPEID, FORM_FIELD_ORGANIZATIONID } from '../../../../Utils/constants/formFieldCodes';
import jwt_decode from "jwt-decode";

const OnboardingCompleteDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const extractPassword = (res) => {
        const parsedPassword = jwt_decode(res.data.token).password;
        props.setParsedPassword(parsedPassword);
    }

    const handleSubmit = () => {
        const entity = props.entityForm.entity;
        const onboarding = new ApiStationOnboarding();
        onboarding.addEntityId(entity.onboardingServiceId);
        onboarding.addStation(entity.stationId);
        onboarding.addEmail(entity.email);

        ApiService.apiPostRequest(onboarding.getPostLink(), onboarding.getData(), extractPassword)
            .then(res => {
                if (res === undefined) {
                    props.setOpenCompleteDialog(false);
                }
            });

    };

    const handleGoBack = () => {
        props.setOpenCompleteDialog(false);
    };

    const formatEntity = (el) => {
        try {
            let formattedEntry = {};
            Object.entries(el).map(([key, value]) => {
                try {
                    // Do not display these fields
                    const isIdField = [FORM_FIELD_ORGANIZATIONID, FORM_FIELD_STATIONTYPEID, FORM_FIELD_ENTITYID].includes(key);
                    if (!isIdField) {
                        value = ["startDate", "endDate"].includes(key) ? value.toDateString() : value;
                        if (props.entityForm.format.hasOwnProperty(key)) {
                            formattedEntry[props.entityForm.format[key]] = value;
                        }
                    }
                } catch (error) {
                    console.error(error)
                }
            })
            return formattedEntry;
        } catch (e) { }
    }


    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            fullScreen={fullScreen}
            open={props.openCompleteDialog}
        >
            <DialogTitle>
                {"Please confirm your changes"}
            </DialogTitle>
            <DialogContent>
                <EntityDisplay
                    entity={formatEntity(props.entityForm.entity)}
                    title={"New " + props.title}
                />
            </DialogContent>
            <DialogActions>
                <StyledOutlinedButton onClick={handleSubmit} autoFocus>
                    Create {props.title}
                </StyledOutlinedButton>
                <StyledOutlinedButton autoFocus onClick={handleGoBack}>
                    Go Back
                </StyledOutlinedButton>
            </DialogActions>
        </Dialog>
    )
}

OnboardingCompleteDialog.propTypes = {
    entityForm: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    openCompleteDialog: PropTypes.bool.isRequired,
    setOpenCompleteDialog: PropTypes.func.isRequired,
    setParsedPassword: PropTypes.func.isRequired,
};

export default OnboardingCompleteDialog;