import { ENDPOINT_ORGANIZATIONS } from "../constants/apiEndpointCodes";
import { API_ATTRIBUTE_ENTITY_ID, API_ATTRIBUTE_NAME } from "../constants/apiAttributeCodes";

/**
 * This is a class
 */
class ApiOrganization {

    dataModel = {
        [API_ATTRIBUTE_ENTITY_ID]: null,
        [API_ATTRIBUTE_NAME]: null
    };
    entityId = null;
    name = null;
    resourceLink = null;
    postLink = ENDPOINT_ORGANIZATIONS;

    addEntityId = (entityId) => {
        this.entityId = entityId;
        this.addResourceLink(entityId);
    }

    addName = (name) => {
        this.name = name;
    }

    /**
     * Link needed for PUT and DELETE requests
     * @param {*} entityId 
     */
    addResourceLink = (entityId) => {
        this.resourceLink = ENDPOINT_ORGANIZATIONS + entityId;
    }

    getPostLink = () => {
        return this.postLink;
    }

    getResourceLink = () => {
        return this.resourceLink;
    }

    getData = () => {
        this.dataModel[API_ATTRIBUTE_ENTITY_ID] = this.entityId;
        this.dataModel[API_ATTRIBUTE_NAME] = this.name;
        return this.dataModel;
    }

}

export default ApiOrganization;