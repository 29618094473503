import * as React from 'react';
import {
    Box,
} from "@mui/material";
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import DeleteCompleteDialog from './CompleteDialogs/DeleteCompleteDialog';
import EditCompleteDialog from './CompleteDialogs/EditCompleteDialog';
import StyledContainedButton from '../../StyledButtons/StyledContainedButton';
import StyledOutlinedButton from '../../StyledButtons/StyledOutlinedButton';
import UserService from '../../../Services/UserService';
import {
    FORM_FIELD_ENTITYID,
    FORM_FIELD_STARTDATE,
    FORM_FIELD_ENDDATE,
} from '../../../Utils/constants/formFieldCodes';
import { UNIT_OF_DAY } from '../../../Utils/constants';


const AlterSectionsEdit = (props) => {
    const [open, setOpen] = useState(false);
    const [entityForm, setEntityForm] = useState(props.defaultForm());
    const [entity, setEntity] = useState(props.defaultForm().entity);
    const [isDisabled, setIsDisabled] = useState(true);
    const [openEditComplete, setOpenEditComplete] = useState(false);
    const [openDeleteComplete, setOpenDeleteComplete] = useState(false);


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setEntity(props.defaultForm().entity);
        setEntityForm(props.defaultForm());
        setOpen(false);
        setIsDisabled(true);
    };

    /**
     * Set entity and entityForm after selecting an id.
     * @param {Array} selectedId 
     */
    const selectEntity = (selectedId) => {
        // Find entity with selectedId
        const selectedEntity = props.rows.find((el) => {
            return el.entityId === selectedId[0];
        });
        setEntity(selectedEntity);
        if (!!selectedEntity) {
            let item = props.defaultForm();
            Object.entries(item.entity).map(([key, value]) => {
                item.entity[key] = !!selectedEntity[key] ? selectedEntity[key] : "";
            })
            setEntityForm(item);
            setIsDisabled(false);
        } else {
            setEntity(props.defaultForm().entity);
            setEntityForm(props.defaultForm());
            setIsDisabled(true);
        }
    }

    /**
     * Return name bound to a specific id in an object list.
     * @param {*} id id as a string
     * @param {*} idList list of objects with schema: {name: <val>, id: <val>}
     * @returns 
     */
    const getNameById = (id, idList) => {
        const element = idList.find((el) => {
            return el.id === id;
        })
        return !!element ? element.name : "";
    }

    /**
     * Adjust the form object each time the inputs change.
     * @param {*} event 
     * @param {*} formLocation corresponding field name of form
     */
    const handleFormChange = (event, formLocation, idList) => {
        let items = { ...entityForm };
        switch (event.constructor) {
            case String:
                items.entity[formLocation] = event;
                break;
            case Array:
                items.entity[formLocation] = event[0];
                break;
            case Date:
                items.entity[formLocation] = event;
                break;
            case window.PointerEvent:
                items.entity[formLocation] = getNameById(event.target.value, idList);
                items.entity[formLocation + "Id"] = event.target.value;
                break;
            default:
                items.entity[formLocation] = getNameById(event.target.value, idList);
                items.entity[formLocation + "Id"] = event.target.value;
                break;
        }
        setEntityForm(items);
    }

    /**
     * Returns true if all entries of an object are false.
     * @param {*} errorList 
     * @returns 
     */
    const isValid = (errorList) => {
        return !Object.values(errorList).find(el => el);
    }

    /**
     * Returns true if one element does not contain the same value as the other.
     * Used to check if at least one value got changed in the form.
     * @param {*} valueList 
     * @returns 
     */
    const hasAlternation = (valueList) => {
        const isAltered = Object.entries(valueList).some(([key, value]) => {
            return entity[key] !== value;
        });
        return isAltered;
    }

    /**
     * Submit the filled in form. 
     * 
     * Submission is successful if:
     * 1. Id field is not empty
     * 2. There is at least one altered field
     * 3. There are no empty fields
     * 4. Check if time difference is positive
     */
    const handleEdit = () => {
        let items = { ...entityForm };

        if (items.entity.entityId === "") {
            items.errors.entityId = true;
        } else if (!hasAlternation(items.entity)) {
            Object.entries(items.errors).map(([key, value]) => {
                if (key !== FORM_FIELD_ENTITYID) {
                    items.errors[key] = true;
                }
            });
        } else {
            items.errors.entityId = false;
            Object.entries(items.entity).some(([key, value]) => {
                // Check if values are empty
                const isDate = [FORM_FIELD_STARTDATE, FORM_FIELD_ENDDATE].includes(key);
                if (!isDate && value.toString().trim() === "") {
                    items.errors[key] = true;
                } else {
                    items.errors[key] = false;
                }
                // Check if time difference is positive
                if (key === FORM_FIELD_STARTDATE) {
                    const diffDays = Math.round((items.entity[FORM_FIELD_ENDDATE] - value) / UNIT_OF_DAY);
                    if (diffDays < 1) {
                        items.errors[key] = true;
                    }
                }
            });
        }
        const isValidEntry = isValid(entityForm.errors);
        setOpenEditComplete(isValidEntry);
        setEntityForm(items);
    };

    const handleDelete = () => {
        let items = { ...entityForm };
        items.entity = { ...entity };
        setEntityForm(items);
        setOpenDeleteComplete(true);
    };

    return (
        <Box sx={{ m: 1 }}>
            <StyledContainedButton
                disabled={UserService.isLoggedIn() ? false : true}
                onClick={handleClickOpen}
            >
                Edit {props.entityName}
            </StyledContainedButton>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    {"Edit or delete a " + props.entityName}
                </DialogTitle>
                <DialogContent>
                    {props.alterInputs(entityForm, selectEntity, isDisabled, handleFormChange)}
                </DialogContent>
                <DialogActions>
                    <StyledOutlinedButton onClick={handleEdit} autoFocus>
                        Apply Changes
                    </StyledOutlinedButton>
                    <StyledOutlinedButton autoFocus onClick={handleDelete}>
                        Delete {props.entityName}
                    </StyledOutlinedButton>
                    <StyledOutlinedButton autoFocus onClick={handleClose}>
                        Cancel
                    </StyledOutlinedButton>
                </DialogActions>
            </Dialog>
            <EditCompleteDialog
                title={props.entityName}
                oldEntry={entity}
                entityForm={entityForm}
                openCompleteDialog={openEditComplete}
                setOpenCompleteDialog={setOpenEditComplete}
                setOpenAlterDialog={setOpen}
            />
            <DeleteCompleteDialog
                title={props.entityName}
                entityForm={entityForm}
                openCompleteDialog={openDeleteComplete}
                setOpenCompleteDialog={setOpenDeleteComplete}
                setOpenAlterDialog={setOpen}
            />
        </Box>
    )
}

AlterSectionsEdit.propTypes = {
    defaultForm: PropTypes.func.isRequired,
    entityName: PropTypes.string.isRequired,
    alterInputs: PropTypes.any.isRequired,
    rows: PropTypes.array.isRequired,
};

export default AlterSectionsEdit;