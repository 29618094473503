import { ENDPOINT_PROJECTS } from "../constants/apiEndpointCodes";
import { 
    API_ATTRIBUTE_ENTITY_ID, 
    API_ATTRIBUTE_END_DATE, 
    API_ATTRIBUTE_START_DATE,
    API_ATTRIBUTE_NAME
} from "../constants/apiAttributeCodes";

/**
 * This is a class
 */
class ApiProject {

    dataModel = {
        [API_ATTRIBUTE_ENTITY_ID]: null,
        [API_ATTRIBUTE_NAME]: null,
        [API_ATTRIBUTE_START_DATE]: null,
        [API_ATTRIBUTE_END_DATE]: null
    };
    entityId = null;
    name = null;
    startDate = null;
    endDate = null;
    postLink = ENDPOINT_PROJECTS;
    resourceLink = null;

    formatDate = (date) => {
        let month = (date.getMonth() + 1).toString();
        month = month.length === 1 ? "0" + month : month;
        let day = date.getDate().toString();
        day = day.length === 1 ? "0" + day : day;
        return day + "-" + month + "-" + date.getFullYear();
    }

    addEntityId = (entityId) => {
        this.entityId = entityId;
        this.addResourceLink(entityId);
    }

    addName = (name) => {
        this.name = name;
    }

    addStartDate = (startDate) => {
        this.startDate = startDate.constructor === Date ? this.formatDate(startDate) : startDate;
    }

    addEndDate = (endDate) => {
        this.endDate = endDate.constructor === Date ? this.formatDate(endDate) : endDate;
    }

    /**
     * Link to a specific ressource. Needed for PUT and DELETE requests
     * @param {*} entityId 
     */
    addResourceLink = (entityId) => {
        this.resourceLink = this.postLink + entityId;
    }

    getPostLink = () => {
        return this.postLink;
    }

    getResourceLink = () => {
        return this.resourceLink;
    }

    getData = () => {
        this.dataModel[API_ATTRIBUTE_ENTITY_ID] = this.entityId;
        this.dataModel[API_ATTRIBUTE_NAME] = this.name;
        this.dataModel[API_ATTRIBUTE_START_DATE] = this.startDate;
        this.dataModel[API_ATTRIBUTE_END_DATE] = this.endDate;
        return this.dataModel;
    }

}

export default ApiProject;