import Keycloak from "keycloak-js";


const _kc = new Keycloak({
    url: window._env_.REACT_APP_KEYCLOAK_ADDRESS,
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT
});

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback, onNotAuthenticatedCallback) => {
    _kc.init(
        {
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
            pkceMethod: 'S256',
            checkLoginIframe: false
        }
    )
        .then((authenticated) => {
            if (!authenticated) {
                console.error("user is not authenticated..!");
            }
            onAuthenticatedCallback();
        })
        .catch((error) => {
            onNotAuthenticatedCallback();
        })
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => {
    return _kc.token;
}

const isLoggedIn = () => {
    if (_kc.token) {
        return true;
    } else {
        return false;
    }
}

const updateToken = (successCallback) => {
    _kc.updateToken(5)
        .then(successCallback)
        .catch(doLogin);
}

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => {
    return roles.some((role) => _kc.hasRealmRole(role));
}

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
};

export default UserService;
