import * as React from 'react';
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import { DRAWERWIDTH, NAVBARHEIGHT } from "../Utils/constants";
import ApiTable from "../Components/Table/ApiTable";
import { useState, useEffect } from 'react';
import ApiService from "../Services/ApiService";
import { STATIONTYPESHEAD } from "../Utils/constants/tableHeaders";
import StationTypesFilter from "../Components/FilterSections/StationTypesFilter";
import StationTypesAlter from '../Components/AlterSections/StationTypesAlter';
import { Box, Button } from '@mui/material';
import StationTypeIcon from '../Components/PageIcons/StationTypeIcon';
import { navbarIconSx } from '../Utils/styles/sxStyles';
import { transformStationTypeData } from '../Utils/helpers/apiTransformation';
import { ENDPOINT_STATION_TYPES } from '../Utils/constants/apiEndpointCodes';
import { TITLE_PLURAL_STATION_TYPE } from '../Utils/constants/titleCodes';
import { useStore } from '../Services/StoreService';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PageWrapper from '../Components/PageWrapper';

const StationTypesPage = () => {

    const [rows, setRows] = useState([]);
    const [dataView, setDataView] = useState([]);
    const [hasData, setHasData] = useState(false);
    const [globalState, dispatch] = useStore();
    
    useEffect(() => {
        if (globalState.isSuccess || !hasData) {
            ApiService.apiGetRequest(ENDPOINT_STATION_TYPES, (res) => transformStationTypeData(res, setRows));
            setDataView(rows);
            setHasData(true);
            dispatch('SUCCESSFUL_REQUEST', false);
        }
    }, [hasData, rows, globalState]);

    return (

        <PageWrapper
            title={TITLE_PLURAL_STATION_TYPE}
            icon={<StationTypeIcon sx={navbarIconSx} />}
        >
            <StationTypesAlter
                rows={rows}
            />
            <StationTypesFilter
                rows={rows}
                setDataView={setDataView}
                setHasData={setHasData}
            />
            <ApiTable
                headCells={STATIONTYPESHEAD}
                rows={dataView}
                setRows={setRows}
            />
        </PageWrapper>
    )
}

export default StationTypesPage;