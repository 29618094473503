export const FORM_FIELD_ORGANIZATIONID = "organizationId";
export const FORM_FIELD_ORGANIZATION = "organization";
export const FORM_FIELD_NAME = "name";
export const FORM_FIELD_STATIONTYPE = "stationType";
export const FORM_FIELD_STATIONTYPEID = "stationTypeId";
export const FORM_FIELD_ENTITYID = "entityId";
export const FORM_FIELD_ONBOARDINGSERVICE = "onboardingService";
export const FORM_FIELD_ONBOARDING = "onboarding";
export const FORM_FIELD_STARTDATE = "startDate";
export const FORM_FIELD_ENDDATE = "endDate";
export const FORM_FIELD_ACCESSLEVEL = "accessLevel";
export const FORM_FIELD_STATION = "station";
export const FORM_FIELD_STATIONID = "stationId";

