import * as React from 'react';
import {
    Box,
    Stack,
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import EntityDisplay from './EntityDisplay';
import StyledOutlinedButton from '../../../StyledButtons/StyledOutlinedButton';
import ApiStation from '../../../../Utils/dataAccessClasses/ApiStation';
import ApiService from '../../../../Services/ApiService';
import ApiOrganization from '../../../../Utils/dataAccessClasses/ApiOrganization';
import ApiStationType from '../../../../Utils/dataAccessClasses/ApiStationType';
import ApiProject from '../../../../Utils/dataAccessClasses/ApiProject';
import ApiOnboardingService from '../../../../Utils/dataAccessClasses/ApiOnboardingService';
import { FORM_FIELD_STATIONTYPEID, FORM_FIELD_ORGANIZATIONID } from '../../../../Utils/constants/formFieldCodes';
import { 
    TITLE_SINGULAR_ONBOARDING, 
    TITLE_SINGULAR_ONBOARDING_SERVICE,
    TITLE_SINGULAR_ORGANIZATION,
    TITLE_SINGULAR_PROJECT,
    TITLE_SINGULAR_STATION,
    TITLE_SINGULAR_STATION_TYPE 
} from '../../../../Utils/constants/titleCodes';
import { useStore } from '../../../../Services/StoreService';

const EditCompleteDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useStore()[1];

    const formatEntity = (el) => {
        let formattedEntry = {};
        Object.entries(el).map(([key, value]) => {
            const notDisplayedFields = [FORM_FIELD_ORGANIZATIONID, FORM_FIELD_STATIONTYPEID, {TITLE_SINGULAR_ONBOARDING}];
            if (!notDisplayedFields.includes(key)) {
                value = ["startDate", "endDate"].includes(key) ? new Date(value).toDateString() : value;
                formattedEntry[props.entityForm.format[key]] = value.toString();
            }
        })
        return formattedEntry;
    }

    const goBackAndRefresh = (response) => {
        let isSuccessful = !!response ? false : true;
        props.setOpenCompleteDialog(!isSuccessful);
        props.setOpenAlterDialog(!isSuccessful);
        dispatch('SUCCESSFUL_REQUEST', isSuccessful);
    };

    const handleSubmit = () => {
        const entity = props.entityForm.entity;
        switch (props.title) {
            case TITLE_SINGULAR_STATION:
                const station = new ApiStation();
                station.addEntityId(entity.entityId);
                station.addOrganization(entity.organizationId);
                station.addStationType(entity.stationTypeId);
                station.addName(entity.name);
                station.addAccessLevel(entity.accessLevel);
                station.addOnboarding(entity.onboarding);
                
                ApiService.apiPutRequest(station.getResourceLink(), station.getData())
                    .then(res => {
                        goBackAndRefresh(res);
                    });
                break;
            case TITLE_SINGULAR_ORGANIZATION:
                const organization = new ApiOrganization();
                organization.addEntityId(entity.entityId);
                organization.addName(entity.name);
                
                ApiService.apiPutRequest(organization.getResourceLink(), organization.getData())
                    .then(res => {
                        goBackAndRefresh(res);
                    });
                break;
            case TITLE_SINGULAR_STATION_TYPE:
                const stationType = new ApiStationType();
                stationType.addEntityId(entity.entityId);
                stationType.addName(entity.name);
                stationType.addDescription(entity.description);
                
                ApiService.apiPutRequest(stationType.getResourceLink(), stationType.getData())
                    .then(res => {
                        goBackAndRefresh(res);
                    });
                break;
            case TITLE_SINGULAR_PROJECT:
                const project = new ApiProject();
                project.addEntityId(entity.entityId);
                project.addName(entity.name);
                project.addStartDate(entity.startDate);
                project.addEndDate(entity.endDate);
                
                ApiService.apiPutRequest(project.getResourceLink(), project.getData())
                    .then(res => {
                        goBackAndRefresh(res);
                    });
                break;
            case TITLE_SINGULAR_ONBOARDING_SERVICE:
                const onboarding = new ApiOnboardingService();
                onboarding.addEntityId(entity.entityId);
                onboarding.addUri(entity.onboardingService);
                onboarding.addStationType(entity.stationTypeId);
                
                
                ApiService.apiPutRequest(onboarding.getResourceLink(), onboarding.getData())
                    .then(res => {
                        goBackAndRefresh(res);
                    });
                break;
            default:
                break;
        }
        
    };

    const handleGoBack = () => {
        props.setOpenCompleteDialog(false);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            fullScreen={fullScreen}
            open={props.openCompleteDialog}
        >
            <DialogTitle>
                {"Do you want to send these changes?"}
            </DialogTitle>
            <DialogContent>
                <Stack direction="row" spacing={4}>
                    <EntityDisplay
                        entity={formatEntity(props.oldEntry)}
                        title={"Old " + props.title}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <ArrowRightAltIcon sx={{ fontSize: 50 }} />
                    </Box>
                    <EntityDisplay
                        entity={formatEntity(props.entityForm.entity)}
                        title={"New " + props.title}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <StyledOutlinedButton onClick={handleSubmit} autoFocus>
                    Send Changes
                </StyledOutlinedButton>
                <StyledOutlinedButton autoFocus onClick={handleGoBack}>
                    Go Back
                </StyledOutlinedButton>
            </DialogActions>
        </Dialog>
    )
}

EditCompleteDialog.propTypes = {
    title: PropTypes.string.isRequired,
    oldEntry: PropTypes.object.isRequired,
    entityForm: PropTypes.object.isRequired,
    openCompleteDialog: PropTypes.bool.isRequired,
    setOpenCompleteDialog: PropTypes.func.isRequired,
    setOpenAlterDialog: PropTypes.func.isRequired,
};

export default EditCompleteDialog;