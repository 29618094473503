import * as React from 'react';
import {
    Button,
    Toolbar,
    Box,
    TextField
} from "@mui/material";
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AlterSectionsAdd from '../../Components/AlterSections/AlterDialogs/AlterSectionsAdd';
import AlterSectionsEdit from '../../Components/AlterSections/AlterDialogs/AlterSectionsEdit';
import FilterInput from '../StyledInputs/StyledTextInput';
import { alterBoxSx } from '../../Utils/styles/sxStyles';
import { filterSelectionSx } from '../../Utils/constants';
import { FORM_FIELD_ENTITYID, FORM_FIELD_NAME } from '../../Utils/constants/formFieldCodes';
import { TITLE_SINGULAR_STATION_TYPE } from '../../Utils/constants/titleCodes';
import { createSelectionList } from '../../Utils/helpers/listCreation';


const getDefaultForm = () => {
    return {
        format: {
            entityId: "Id",
            name: "Name",
            description: "Description"
        },
        entity: {
            entityId: "",
            name: "",
            description: "",
        },
        errors: {
            entityId: false,
            name: false,
            description: false,
        }
    }
}

const StationTypesAlter = (props) => {
    const { rows } = props;
    const [entityIds, setEntityIds] = useState([]);

    useEffect(() => {
        createSelectionList(rows, FORM_FIELD_ENTITYID, setEntityIds);
    }, [rows]);

    const addInputs = (entityForm, handleFormChange) => {
        return (
            <Box>
                <TextField
                    error={entityForm.errors.name}
                    helperText={entityForm.errors.name ? "Please fill in a station type" : null}
                    sx={filterSelectionSx}
                    onChange={(event) => handleFormChange(event.target.value, FORM_FIELD_NAME)}
                    label="Station Name"
                    variant="outlined"
                />
                <TextField
                    error={entityForm.errors.description}
                    helperText={entityForm.errors.description ? "Please fill in a description" : null}
                    sx={filterSelectionSx}
                    onChange={(event) => handleFormChange(event.target.value, "description")}
                    label="Description"
                    variant="outlined"
                />
            </Box>
        )
    }

    const editInputs = (entityForm, selectEntity, isDisabled, handleFormChange) => {
        return (
            <Box>
                <FilterInput
                    sx={{ m: 1, width: "80%" }}
                    label={"IDs"}
                    setSelection={selectEntity}
                    optionList={entityIds}
                    multiSelect={false}
                    isError={entityForm.errors.entityId}
                />
                <TextField
                    sx={filterSelectionSx}
                    error={entityForm.errors.name}
                    label={TITLE_SINGULAR_STATION_TYPE}
                    value={entityForm.entity.name}
                    onChange={(event) => handleFormChange(event.target.value, FORM_FIELD_NAME)}
                    disabled={isDisabled}
                />
                <TextField
                    sx={filterSelectionSx}
                    error={entityForm.errors.description}
                    label={"Description"}
                    value={entityForm.entity.description}
                    onChange={(event) => handleFormChange(event.target.value, "description")}
                    disabled={isDisabled}
                />
            </Box>
        )
    }    

    return (
        <Box sx={alterBoxSx}>
            <Toolbar>
                <AlterSectionsAdd
                    defaultForm={getDefaultForm}
                    entityName={TITLE_SINGULAR_STATION_TYPE}
                    alterInputs={addInputs}
                />
                <AlterSectionsEdit
                    rows={props.rows}
                    entityName={TITLE_SINGULAR_STATION_TYPE}
                    alterInputs={editInputs}
                    defaultForm={getDefaultForm}
                />
            </Toolbar>
        </Box>
    )
}

StationTypesAlter.propTypes = {
    rows: PropTypes.array.isRequired,
};



export default StationTypesAlter;